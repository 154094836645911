import {
  FC,
  memo,
  useMemo,
  useState,
  useCallback,
  useEffect,
  ReactNode,
  MouseEvent,
} from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./header-navigate.scss";

interface MenuListItemsProps {
  target: string;
  value: string;
  href?: string;
  iconSrc?: string;
  hasTypeExit?: boolean;
}

interface MenuListProps {
  baseClass: string;
  handleClicItem?: (value: string) => void;
  items: Array<MenuListItemsProps>;
}

interface HeaderNavigateProps {
  menuList: MenuListProps;
  extraLinks?: MenuListProps;
  children?: ReactNode;
  customClass?: string;
  isForAdmin?: boolean;
}

const isMobile = !!navigator.userAgent.match(/iPhone|iPod|iPad|iOS|android/i);

const HeaderNavigate: FC<HeaderNavigateProps> = memo(
  ({ menuList, extraLinks, customClass, isForAdmin, children }) => {
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const [isMenuShow, setIsMenuShow] = useState(false);

    const closeMenu = useCallback(() => {
      setIsMenuShow(false);

      document.documentElement.classList.remove(
        "-overflow-hidden",
        "-header-open"
      );
      document.body.classList.remove("-overflow-hidden", "-header-open");
    }, [setIsMenuShow]);

    const handleClickViewMenu = useCallback(() => {
      document.documentElement.classList.toggle("-overflow-hidden");
      document.body.classList.toggle("-overflow-hidden");

      document.documentElement.classList.toggle("-header-open");
      document.body.classList.toggle("-header-open");

      setIsMenuShow((isMenuShow) => {
        return !isMenuShow;
      });
    }, [setIsMenuShow]);

    const handleClickMenuItem = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        const target = event.currentTarget.dataset.target;
        const type = event.currentTarget.dataset.type;

        if (target && menuList) {
          if (type === "exit") {
            localStorage.removeItem("access_token");
          }

          closeMenu();

          if (menuList.handleClicItem) {
            menuList.handleClicItem(target);
          } else {
            navigate(target);
          }
        }
      },
      [menuList, closeMenu, navigate]
    );

    const renderMenu = useMemo(
      () => (block: MenuListProps, type: string) => {
        return (
          <ul className={`${block.baseClass}__${type}`}>
            {block.items.map((item: MenuListItemsProps) => (
              <li
                className={`${block.baseClass}__${type}item`}
                key={item.target}
              >
                {item.href ? (
                  <a
                    href={item.href}
                    data-type={item.hasTypeExit ? "exit" : ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item.iconSrc && (
                      <span className={`${block.baseClass}__${type}icon`}>
                        <img src={item.iconSrc} alt="" />
                      </span>
                    )}
                    {item.value}
                  </a>
                ) : (
                  <button
                    data-type={item.hasTypeExit ? "exit" : ""}
                    data-target={item.target}
                    onClick={handleClickMenuItem}
                    className={
                      item.target === currentLocation.pathname ? "-active" : ""
                    }
                  >
                    {item.iconSrc && (
                      <span className={`${block.baseClass}__${type}icon`}>
                        <img src={item.iconSrc} alt="" />
                      </span>
                    )}
                    {item.value}
                  </button>
                )}
              </li>
            ))}
          </ul>
        );
      },
      [handleClickMenuItem, currentLocation.pathname]
    );

    useEffect(() => {
      const viewMenu = () => {
        const breakpoint = isForAdmin
          ? "(max-width: 1023px)"
          : "(max-width: 767px)";

        if (window.matchMedia(breakpoint).matches) {
          closeMenu();
        }
      };

      if (isMobile) {
        window.addEventListener("orientationchange", viewMenu, false);
      } else {
        window.addEventListener("resize", viewMenu, false);
      }

      return () => {
        if (isMobile) {
          window.removeEventListener("orientationchange", viewMenu, false);
        } else {
          window.removeEventListener("resize", viewMenu, false);
        }
      };
    }, [isForAdmin, closeMenu]);

    return (
      <nav
        className={`header-navigate ${customClass}${
          isMenuShow ? " -open" : ""
        }`}
      >
        <button
          className={`header-navigate__door${
            isForAdmin ? " -admin" : " -main"
          }`}
          onClick={handleClickViewMenu}
        >
          <svg
            className="-burger"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21 18C21.828 18 22.5 18.672 22.5 19.5C22.5 20.328 21.828 21 21 21H3C2.172 21 1.5 20.328 1.5 19.5C1.5 18.672 2.172 18 3 18H21ZM21 10.5C21.828 10.5 22.5 11.172 22.5 12C22.5 12.828 21.828 13.5 21 13.5H3C2.172 13.5 1.5 12.828 1.5 12C1.5 11.172 2.172 10.5 3 10.5H21ZM21 3C21.828 3 22.5 3.672 22.5 4.5C22.5 5.328 21.828 6 21 6H3C2.172 6 1.5 5.328 1.5 4.5C1.5 3.672 2.172 3 3 3H21Z"
            />
          </svg>
          <svg
            className="-close"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2391 8.32906L15.6641 2.90406C16.2028 2.36531 17.0778 2.36531 17.6166 2.90406C18.1553 3.44281 18.1553 4.31781 17.6166 4.85656L12.1916 10.2816L17.6166 15.7066C18.1441 16.2328 18.1441 17.0891 17.6166 17.6166C17.0891 18.1441 16.2328 18.1441 15.7066 17.6166L10.2816 12.1916L4.85656 17.6166C4.31781 18.1553 3.44281 18.1553 2.90406 17.6166C2.36531 17.0778 2.36531 16.2028 2.90406 15.6641L8.32906 10.2391L2.90406 4.81406C2.37656 4.28781 2.37656 3.43156 2.90406 2.90406C3.43156 2.37656 4.28781 2.37656 4.81406 2.90406L10.2391 8.32906Z"
            />
          </svg>
        </button>
        <div
          className={`header-navigate__frame${
            isForAdmin ? " -admin" : " -main"
          }`}
        >
          {menuList && menuList.items.length > 0 && (
            <>{renderMenu(menuList, "menu")}</>
          )}

          {extraLinks && extraLinks.items.length > 0 && (
            <>{renderMenu(extraLinks, "links")}</>
          )}

          {children && <>{children}</>}
        </div>
      </nav>
    );
  }
);

export default HeaderNavigate;
