import { FC, memo } from "react";

import "./info.scss";

import ImgInfo from "./info.png";
import ImgInfo2x from "./info-2x.png";
import ImgInfoMobile from "./info-mobile.png";
import ImgInfoMobile2x from "./info-mobile-2x.png";

interface InfoProps {}

const Info: FC<InfoProps> = memo(() => {
  return (
    <section className="info">
      <div className="wrap">
        <div className="info__body">
          <h2 className="info__title">
            Фонд открыт как для малых так и&nbsp;для крупных инвесторов
          </h2>
          <div className="info__text">
            Инвестиции начинаются от&nbsp;$1000 до&nbsp;полного выкупа объекта.
          </div>
          <picture className="info__img">
            <source
              media="(max-width: 1023px)"
              srcSet={`${ImgInfoMobile} 1x, ${ImgInfoMobile2x} 2x`}
            />
            <img
              src={ImgInfo2x}
              srcSet={`${ImgInfo} 1x ${ImgInfo2x} 2x`}
              alt=""
            />
          </picture>
        </div>
      </div>
    </section>
  );
});

export default Info;
