import { FC, memo, useCallback, useEffect, useState } from "react";

import IconSuccess from "./ic-success.svg";
import IconFailed from "./ic-failed.svg";

import "./notification-subscribe.scss";

import Button from "../../../../ui/button";

interface NotificationSubscribeProps {
  status: "success" | "failed";
}

const NotificationSubscribe: FC<NotificationSubscribeProps> = memo(
  ({ status }) => {
    const [isOpened, setIsOpened] = useState(true);

    const closeNotification = useCallback(() => {
      document.documentElement.classList.remove("-overflow-hidden");
      document.body.classList.remove("-overflow-hidden");

      setIsOpened(false);
    }, []);

    useEffect(() => {
      document.documentElement.classList.toggle("-overflow-hidden");
      document.body.classList.toggle("-overflow-hidden");

      return () => {
        document.documentElement.classList.remove("-overflow-hidden");
        document.body.classList.remove("-overflow-hidden");
      };
    }, []);

    if (!isOpened) {
      return null;
    }

    return (
      <div className="notification-subscribe">
        <div className="notification-subscribe__body">
          {status === "success" && (
            <>
              <img
                src={IconSuccess}
                alt=""
                className="notification-subscribe__icon"
              />
              <div className="notification-subscribe__title">
                Вы успешно подписаны!
              </div>
              <p className="notification-subscribe__text">
                Рассылка скоро придёт Вам в&nbsp;личные сообщения
              </p>
              <div className="notification-subscribe__button">
                <Button
                  className="-purpule -size-m"
                  onClick={closeNotification}
                >
                  ОК
                </Button>
              </div>
            </>
          )}

          {status === "failed" && (
            <>
              <img
                src={IconFailed}
                alt=""
                className="notification-subscribe__icon"
              />
              <div className="notification-subscribe__title">
                Не&nbsp;удалось подписаться на&nbsp;рассылку
              </div>
              <div className="notification-subscribe__button">
                <Button
                  className="-purpule -size-m"
                  onClick={closeNotification}
                >
                  Повторить попытку
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default NotificationSubscribe;
