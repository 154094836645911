import { FC, memo, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import ruLocate from "date-fns/locale/ru";

import { formattedNumber } from "../../../helpers";

import { projectStatusName } from "../../../helpers";
import Button from "../../../ui/button";
import ActionPlusMinus from "../../../ui/action-plus-minus";

import "./project-card-info.scss";

interface ProjectCardInfoCostProps {
  value: string;
  currency: string;
}

interface ProjectCardInfoProps {
  squareValue: number;
  projectId: string;
  category: string;
  location: string;
  description: string;
  square: string;
  collected_square: string;
  cost: ProjectCardInfoCostProps;
  collected_amount: ProjectCardInfoCostProps;
  price: ProjectCardInfoCostProps;
  profitabilityPercent: string;
  buildingStart: string;
  buildingEnd: string;
  status: string;
  onClickInvest: (projectId: string) => void;
  onChangeSquareValue: (value: number) => void;
  imageLogo?: string;
  investStart?: string;
  investEnd?: string;
  square_area?: string;
}

const ProjectCardInfo: FC<ProjectCardInfoProps> = memo(
  ({
    squareValue,
    projectId,
    category,
    location,
    description,
    square,
    collected_square,
    cost,
    collected_amount,
    price,
    buildingStart,
    buildingEnd,
    investStart,
    investEnd,
    status,
    imageLogo,
    profitabilityPercent,
    square_area,
    onClickInvest,
    onChangeSquareValue,
  }) => {
    const statusProject = useMemo(() => {
      return projectStatusName(status);
    }, [status]);

    const widthProgressBar = useMemo(() => {
      if (cost.value && collected_amount.value) {
        const width = Math.round(
          (Number(collected_amount.value) * 100) / Number(cost.value)
        );

        return width > 1 ? width : 1;
      }

      return 0;
    }, [cost, collected_amount]);

    const handleClickInvest = useCallback(() => {
      onClickInvest(projectId);
    }, [onClickInvest, projectId]);

    const handleChangeSquareValue = useCallback(
      (value: number) => {
        onChangeSquareValue(value);
      },
      [onChangeSquareValue]
    );

    return (
      <section className="project-card-info wrap">
        <div className="project-card-info__list">
          <div className="project-card-info__itemname">Категория</div>
          <div className="project-card-info__itemvalue">{category}</div>
          <div className="project-card-info__itemname">Расположение</div>
          <div className="project-card-info__itemvalue">{location}</div>

          {collected_square && (
            <>
              <div className="project-card-info__itemname">Площадь зданий</div>
              <div className="project-card-info__itemvalue">
                {formattedNumber(Number(collected_square))} м²
              </div>
            </>
          )}

          <div className="project-card-info__itemname">Площадь территории</div>
          <div className="project-card-info__itemvalue">
            {formattedNumber(Number(square))} м²
          </div>

          {square_area && (
            <>
              <div className="project-card-info__itemname">
                Общая площадь территории
              </div>
              <div className="project-card-info__itemvalue">
                {formattedNumber(Number(square_area))} м²
              </div>
            </>
          )}

          <div className="project-card-info__itemname">Стоимость объекта</div>
          <div className="project-card-info__itemvalue -text-uppercase">
            {formattedNumber(Number(cost.value))} {cost.currency}
          </div>
          <div className="project-card-info__itemname">Доходность</div>
          <div className="project-card-info__itemvalue">
            {profitabilityPercent}%
          </div>
          <div className="project-card-info__itemname">
            Начало строительства
          </div>
          <div className="project-card-info__itemvalue">
            {buildingStart &&
              format(new Date(buildingStart), "dd.MM.yyyy", {
                locale: ruLocate,
              })}
          </div>
          <div className="project-card-info__itemname">
            Завершение строительства
          </div>
          <div className="project-card-info__itemvalue">
            {buildingEnd &&
              format(new Date(buildingEnd), "dd.MM.yyyy", {
                locale: ruLocate,
              })}
          </div>
          <div className="project-card-info__itemname">Статус</div>
          <div className="project-card-info__itemvalue">
            {statusProject && (
              <>
                {statusProject}

                {status === "start" &&
                  investStart &&
                  ` ${format(new Date(investStart), "dd.MM.yyyy", {
                    locale: ruLocate,
                  })}`}

                {status === "funded" &&
                  investEnd &&
                  ` до ${format(new Date(investEnd), "dd.MM.yyyy", {
                    locale: ruLocate,
                  })}`}
              </>
            )}
          </div>
        </div>

        <div className="project-card-info__descr">
          {description}
          {imageLogo && (
            <img
              src={imageLogo}
              alt=""
              className="project-card-info__descrlogo"
            />
          )}
        </div>

        <div className="project-card-info__cost">
          <div className="project-card-info-progress">
            <div className="project-card-info-progress__title">
              Вложено / Осталось
            </div>
            <div className="project-card-info-progress__values">
              <div className="project-card-info-progress__valueamount">
                {formattedNumber(Number(collected_amount.value))}{" "}
                {cost.currency}
              </div>
              <div>
                {formattedNumber(
                  Number(cost.value) - Number(collected_amount.value)
                )}{" "}
                {cost.currency}
              </div>
            </div>
            <div className="project-card-info-progress__bar">
              <div className="project-card-info-progress__barinner">
                <div style={{ width: `${widthProgressBar}%` }}></div>
              </div>
            </div>
          </div>

          <div className="project-card-info__costtitle">
            Стоимость {squareValue} кв. м.
          </div>
          <div className="project-card-info__costvalue">
            <div className="project-card-info__costvaluesumm -text-uppercase">
              {formattedNumber(squareValue * Number(price.value))}{" "}
              {price.currency}
            </div>
            <ActionPlusMinus
              value={squareValue}
              onChangeValue={handleChangeSquareValue}
              maxValue={Number(square) - Number(collected_square)}
            />
          </div>
          <div className="project-card-info__costinvest">
            <Button className="-purpule -size-m" onClick={handleClickInvest}>
              Инвестировать
            </Button>
          </div>
          {/*<button className="project-card-info__costcalculate">
            Рассчитать доходность
          </button> */}
        </div>
      </section>
    );
  }
);

export default ProjectCardInfo;
