import { FC, memo, useCallback, useState } from "react";

import Tabs from "../../ui/tabs";
import Headline from "../../ui/headline";
import ProjectsList from "../../ui/projects-list";

import MonitoringInvestments from "./monitoring-investments";

interface MonitoringProps {}

const tabItems = [
  { value: "list", text: "Мои проекты" },
  { value: "favourite", text: "Избранное" },
];

const Monitoring: FC<MonitoringProps> = memo(() => {
  const [variant, setVariant] = useState<"investments" | "favorite">(
    "investments"
  );

  const handleClickTabItem = useCallback(
    (value: string) => {
      if (value === "list") {
        setVariant("investments");
      } else {
        setVariant("favorite");
      }
    },
    [setVariant]
  );

  return (
    <>
      <main className="wrap main">
        <Headline>Мониторинг</Headline>
        <Tabs list={tabItems} current="list" onClickItem={handleClickTabItem} />
        {variant === "investments" && <MonitoringInvestments />}
        {variant === "favorite" && <ProjectsList favorite={true} />}
      </main>
    </>
  );
});

export default Monitoring;
