import { FC, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { routes } from "../../../routing";
import Button from "../../../ui/button";

import "./payment-finish.scss";

interface PaymentFinishProps {
  squareValue: number;
  projectName: string;
}

const PaymentFinish: FC<PaymentFinishProps> = memo(
  ({ squareValue, projectName }) => {
    const navigate = useNavigate();

    const handleClickMonitoring = useCallback(() => {
      navigate(routes.monitoring.path);
    }, [navigate]);

    return (
      <div className="payment-finish">
        <h2 className="payment-finish__title">Поздравляем!</h2>
        <p className="payment-finish__text">
          Вы инвестировали в {squareValue} кв. м. в проекте <b>{projectName}</b>
          . Следить за своими проектами вы можете на странице Мониторинг.
        </p>
        <Button
          type="button"
          className="-purpule -size-m"
          onClick={handleClickMonitoring}
        >
          Перейти в Мониторинг
        </Button>
      </div>
    );
  }
);

export default PaymentFinish;
