import { FC, memo } from "react";

import Img from "./logo.svg";

interface LogoProps {
  className?: string;
}

const LogoMain: FC<LogoProps> = memo(({ className }) => {
  return <img src={Img} alt="Cryptometers" className={className} />;
});

export default LogoMain;
