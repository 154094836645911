import { FC, memo } from "react";

import "./footer-links.scss";

interface FooterLinksProps {
  isForAdmin?: boolean;
}

const FooterLinks: FC<FooterLinksProps> = memo(({ isForAdmin }) => {
  return (
    <ul className={`footer-links${isForAdmin ? " -admin" : ""}`}>
      <li className="footer-links__item">
        <a href="https://cryptometers.io/" rel="noopener noreferrer">
          Политикой обработки Cookie-файлов
        </a>
      </li>
      <li className="footer-links__item">
        <a href="https://cryptometers.io/" rel="noopener noreferrer">
          Политикой Конфиденциальности
        </a>
      </li>
      <li className="footer-links__item">
        <a href="https://cryptometers.io/" rel="noopener noreferrer">
          Условиями использования
        </a>
      </li>
    </ul>
  );
});

export default FooterLinks;
