import { FC, memo } from "react";

import "./benefit.scss";

interface BenefitProps {}

const Benefit: FC<BenefitProps> = memo(() => {
  return (
    <section className="benefit wrap">
      <h2 className="benefit__title">Преимущества</h2>
      <div className="benefit__content">
        <div className="benefit__col">
          <div className="benefit__item -color1">
            <div className="benefit__text">Выгоднее бансковских вкладов</div>
          </div>
          <div className="benefit__item -color2">
            <div className="benefit__text">
              Переоценка стоимости с учетом текущей экономической ситуации
            </div>
          </div>
        </div>
        <div className="benefit__col">
          <div className="benefit__item -color2">
            <div className="benefit__text">
              Не требует подтверждения происхождения денежных средств
            </div>
          </div>
          <div className="benefit__item -color1">
            <div className="benefit__text">
              Стоимость недвижимости растет вместе с инфляцией
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Benefit;
