import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/me
 *
 */

 export async function getProfileData() {
  const result = await fetchFromApi({url: `/me`, method: 'GET'});
   
  return result;
}