import { FC, memo, ReactNode } from "react";

import "./message.scss";

interface MessageProps {
  variant?: "error" | "info" | "success";
  children: ReactNode;
  className?: string;
}

const Message: FC<MessageProps> = memo(({ variant, children, className }) => {
  return (
    <div
      className={`message${variant ? " -" + variant : ""}${
        className ? " " + className : ""
      }`}
    >
      {children}
    </div>
  );
});

export default Message;
