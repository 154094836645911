import { FC, memo } from "react";
import "./project-card-cover.scss";

interface ProjectCardCoverProps {
  src: string;
}

const ProjectCardCover: FC<ProjectCardCoverProps> = memo(({ src }) => {
  return (
    <section className="project-card-cover">
      <img src={src} alt="" className="project-card-cover__img" />
    </section>
  );
});

export default ProjectCardCover;
