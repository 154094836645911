import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/me
 *
 */

 export interface EditProfileDataData {
  email: string;
  email_subscribe?: boolean;
}

 export async function editProfileData(data: EditProfileDataData) {
  const result = await fetchFromApi({url: `/me`, method: 'PUT', data: data});
   
  return result;
}