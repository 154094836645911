import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import "./timer.scss";

export interface TimerProps {
  expiryTime: number;
  remainsTime?: number;
  notifyMessage?: string;
}

const Timer: FC<TimerProps> = memo(
  ({ expiryTime, remainsTime, notifyMessage }) => {
    const [seconds, setSeconds] = useState<number>(0);
    const timerRef = useRef<number>();

    const clear = useCallback(() => {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }, []);

    const start = useCallback(
      (expireTime: number) => {
        setSeconds(expireTime);

        clear();
        timerRef.current = window.setInterval(
          () => setSeconds((prevSeconds) => --prevSeconds),
          1_000
        );
      },
      [clear]
    );

    const formattedTime = useCallback(() => {
      const minutes = Math.floor(seconds / 60);
      const sec = seconds - minutes * 60;

      return `${minutes.toString().padStart(2, "0")}:${sec
        .toString()
        .padStart(2, "0")}`;
    }, [seconds]);

    useEffect(() => {
      if (seconds <= 0) {
        clear();
      }
    }, [seconds, clear]);

    useEffect(() => {
      start(expiryTime);

      return () => clearInterval(timerRef.current);
    }, [expiryTime, start]);

    return (
      <div className="timer">
        <div className="timer__time">
          Время:{" "}
          <span
            className={`timer__value${
              remainsTime && seconds <= remainsTime ? " -highlighted" : ""
            }`}
          >
            {formattedTime()}
          </span>
        </div>
        {notifyMessage && remainsTime && seconds <= remainsTime && (
          <div className="timer__message">{notifyMessage}</div>
        )}
      </div>
    );
  }
);

export default Timer;
