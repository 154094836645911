import { FC, memo, useEffect, useMemo, useCallback } from "react";

import useMutation from "../../../hooks/use-mutation";
import { getBalancesSummary, getInvestmentsSummary } from "../../../api";

import Headline from "../../../ui/headline";
import Message from "../../../ui/message";
import Button from "../../../ui/button";
import PaymentOperationsList from "../../../ui/payment-operations-list";

import "../wallet.scss";

interface WalletInfoProps {
  onAddBalances: () => void;
}

const WalletInfo: FC<WalletInfoProps> = memo(({ onAddBalances }) => {
  const [mutateGetBalancesSummary, responseGetBalancesSummary] =
    useMutation(getBalancesSummary);

  const [mutateGetInvestmentsSummary, responseGetInvestmentsSummary] =
    useMutation(getInvestmentsSummary);

  const walletData = useMemo(() => {
    if (
      !responseGetBalancesSummary.isPending &&
      responseGetBalancesSummary.isSuccess &&
      responseGetBalancesSummary.data &&
      !responseGetInvestmentsSummary.isPending &&
      responseGetInvestmentsSummary.isSuccess &&
      responseGetInvestmentsSummary.data
    ) {
      const balancesData = responseGetBalancesSummary.data.response;
      const investmentsData = responseGetInvestmentsSummary.data.response;

      return {
        total: `${balancesData.total.value} ${balancesData.total.currency}`,
        available: `${balancesData.available.value} ${balancesData.available.currency}`,
        profit: `${balancesData.profit.value} ${balancesData.profit.currency}`,
        project_count: investmentsData.project_count,
        square: investmentsData.square,
      };
    }
    return undefined;
  }, [responseGetBalancesSummary, responseGetInvestmentsSummary]);

  useEffect(() => {
    mutateGetBalancesSummary();
    mutateGetInvestmentsSummary();
  }, [mutateGetBalancesSummary, mutateGetInvestmentsSummary]);

  const handleRefresh = useCallback(() => {
    mutateGetBalancesSummary();
  }, [mutateGetBalancesSummary]);

  return (
    <main className="wrap main wallet">
      <div className="wallet__body">
        <Headline>Кошелек</Headline>

        {(responseGetBalancesSummary.isPending ||
          responseGetInvestmentsSummary.isPending) && <>Загружаю...</>}

        {((!responseGetBalancesSummary.isPending &&
          responseGetBalancesSummary.isFailed) ||
          (!responseGetBalancesSummary.isPending &&
            responseGetBalancesSummary.isFailed)) && (
          <Message variant="error">Не удалось загрузить данные</Message>
        )}

        {walletData && (
          <>
            <div className="wallet-table -header">
              <div className="wallet-table__rowtitle"></div>
              <div className="wallet-table__items">
                <div>USDT</div>
                <div>Квадратные метры</div>
              </div>
            </div>
            <div className="wallet-table">
              <div className="wallet-table__rowtitle">Баланс</div>
              <div className="wallet-table__items">
                <div>{walletData.total}</div>
                <div>
                  {walletData.project_count} проекта / {walletData.square} кв.
                  м.
                </div>
              </div>
              <div className="wallet-table__rowtitle">Доступно</div>
              <div className="wallet-table__items">
                <div>{walletData.available}</div>
                <div></div>
              </div>
              <div className="wallet-table__rowtitle">Доход</div>
              <div className="wallet-table__items">
                <div>{walletData.profit}</div>
                <div></div>
              </div>
            </div>
            <Button
              type="button"
              className="-purpule -size-m"
              onClick={onAddBalances}
            >
              Пополнить баланс
            </Button>
          </>
        )}

        <div className="wallet__history">
          <PaymentOperationsList onRefresh={handleRefresh} />
        </div>
      </div>
    </main>
  );
});

export default WalletInfo;
