import { FC, memo, useContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import fetchFromApi from "../../../../api";
import { MetamaskContext } from "../../../../context/metamask-context";

import { routes } from "../../../../routing";

import Button from "../../../../ui/button";
import Message from "../../../../ui/message";

interface ConnectWalletProps {}

interface MyResponseError {
  code: number;
  message: string;
}

const ConnectWallet: FC<ConnectWalletProps> = memo(() => {
  const navigate = useNavigate();

  const { isScriptMetamaskLoaded } = useContext(MetamaskContext);
  const [error, setError] = useState<string>();
  const [warning, setWarning] = useState<string>();
  const [isPending, setIsPending] = useState(false);

  const authMetamask = useCallback(async () => {
    setIsPending(true);
    setWarning("");

    if (isScriptMetamaskLoaded) {
      if (window.ethereum) {
        console.log("ethereum.request");

        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const address = accounts[0];

          const result = await fetchFromApi({
            url: `/auth/nonce`,
            method: "POST",
            data: { provider: "metamask", address: address },
          });

          if (result.status !== 200) {
            throw new Error();
          }

          const signature = await window.ethereum.request({
            method: "personal_sign",
            params: [result.response.nonce, address],
          });

          const responseAuth = await fetchFromApi({
            url: `/auth`,
            method: "POST",
            data: {
              provider: "metamask",
              address: address,
              signature: signature,
            },
          });

          if (responseAuth.status !== 200) {
            throw new Error();
          }

          /*if (responseAuth.status !== 200) {
            const responseError = {
              code: result.status,
              message:
                "Что-то пошло не так, перегрузите страницу и попробуйте пройти заново",
            };

            let err = new Error();

            err = { ...err, ...responseError };
            throw err;
          }*/

          console.log("Сохраняем токен в localstorage");

          localStorage.setItem(
            "access_token",
            responseAuth.response.access_token
          );

          navigate(routes.monitoring.path);

          console.log(responseAuth);
        } catch (error) {
          setIsPending(false);
          console.log(error);

          if (
            error instanceof Object &&
            (error as MyResponseError).code === 4001
          ) {
            setWarning("Пользователь отклонил операцию");
          } else {
            setError(
              "Что-то пошло не так, перегрузите страницу и попробуйте пройти заново"
            );
          }
        }
      } else {
        setIsPending(false);
        setError("Расширение metamask не установлено");
        window.open("https://metamask.io/download/", "_blank");
      }
    } else {
      setIsPending(false);
      setError(
        "скрипт проверки Metamask не загрузился, попробуйте обновить страницу"
      );
    }
  }, [isScriptMetamaskLoaded, navigate]);

  return (
    <>
      {isPending && <>Подключение к кошельку ...</>}

      {!isPending && !error && (
        <Button className="-blue -size-s" onClick={authMetamask}>
          Подключить кошелек
        </Button>
      )}

      {warning && <Message variant="info">{warning}</Message>}

      {error && <Message variant="error">{error}</Message>}
    </>
  );
});

export default ConnectWallet;
