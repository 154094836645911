import { FC, memo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ProviderMetamask from "./provider/provider-metamask";

import "./app.scss";

import { routes } from "./routing";

import Main from "./pages/main";
import Projects from "./pages/projects";
import ProjectCard from "./pages/project-card";
import Monitoring from "./pages/monitoring";
import Feedback from "./pages/feedback";
import Profile from "./pages/profile";
import Payment from "./pages/payment";
import Wallet from "./pages/wallet";

import InfoCookies from "./ui/info-cookies";
import Layout from "./ui/layout";

const App: FC = memo(() => {
  return (
    <>
      <ProviderMetamask>
        <Router>
          <Routes>
            <Route
              path={routes.projectView.path}
              element={
                <Layout styleHeader="dark">
                  <ProjectCard />
                </Layout>
              }
            />
            <Route
              path={routes.projects.path}
              element={
                <Layout>
                  <Projects />
                </Layout>
              }
            />
            <Route
              path={routes.monitoring.path}
              element={
                <Layout>
                  <Monitoring />
                </Layout>
              }
            />
            <Route
              path={routes.wallet.path}
              element={
                <Layout>
                  <Wallet />
                </Layout>
              }
            />
            <Route
              path={routes.profile.path}
              element={
                <Layout>
                  <Profile />
                </Layout>
              }
            />
            <Route
              path={routes.feedback.path}
              element={
                <Layout>
                  <Feedback />
                </Layout>
              }
            />
            <Route
              path={routes.payment.path}
              element={
                <Layout>
                  <Payment />
                </Layout>
              }
            />
            <Route
              path={routes.root.path}
              element={
                <Layout variant="index" hasHeader={false} hasFooter={false}>
                  <Main />
                </Layout>
              }
            />
            <Route path="*" element={<>Страница не найдена</>} />
          </Routes>
        </Router>
      </ProviderMetamask>
      <InfoCookies />
    </>
  );
});

export default App;
