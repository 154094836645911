import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/projects/ID/investment
 *
 */

 export interface GetProjectInvestmentData {
  id: string;
}

 export async function getProjectInvestment(data: GetProjectInvestmentData) {
  const result = await fetchFromApi({url: `/projects/${data.id}/investment`, method: 'GET'});
   
  return result;
}