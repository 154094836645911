import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/projects/ID
 *
 */

 export interface GetProjectByIdData {
  id: string;
}

 export async function getProjectById(data: GetProjectByIdData) {
  const result = await fetchFromApi({url: `/projects/${data.id}`, method: 'GET'});
   
  return result;
}