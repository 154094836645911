import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/operations?order_by=id&order_flow=desc&offset=0&page=1&limit=100
 *
 */


 export async function getPaymentOperations() {
  const result = await fetchFromApi({url: `/operations?order_by=id&order_flow=desc&offset=0&page=1&limit=100`, method: 'GET'});
   
  return result;
}