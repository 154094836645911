import { FC, memo, useMemo, useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing";
import useMutation from "../../../hooks/use-mutation";
import { getInvestmentsList } from "../../../api";

import { projectStatusName } from "../../../helpers";

import Message from "../../../ui/message";
import ProjectsTable from "../../../ui/projects-table";

interface MonitoringInvestmentsProps {}

const MonitoringInvestments: FC<MonitoringInvestmentsProps> = memo(() => {
  const navigate = useNavigate();

  const [mutateGetInvestmentsList, responseGetInvestmentsList] =
    useMutation(getInvestmentsList);

  const formattedData = useMemo(() => {
    if (
      !responseGetInvestmentsList.isPending &&
      responseGetInvestmentsList.isSuccess &&
      responseGetInvestmentsList.data
    ) {
      return responseGetInvestmentsList.data.response.map((item: any) => {
        const statusProject = projectStatusName(item.project.status);

        return {
          id: item.project.id,
          imageSrc: item.project.image_profile,
          name: item.project.name,
          price: `${item.project.price.value} ${item.project.price.currency}`,
          status: statusProject ? statusProject : "",
          profitabilityPercent: item.forecast_percent,
          cost: item.project.cost
            ? `${item.project.cost.value} ${item.project.cost.currency}`
            : "",
          earning: item.earning
            ? `${item.earning.value} ${item.earning.currency}`
            : "",
          amount: item.amount
            ? `${item.amount.value} ${item.amount.currency}`
            : "",
        };
      });
    }

    return [];
  }, [responseGetInvestmentsList]);

  const handleClickItem = useCallback(
    (value: string) => {
      navigate(routes.projectView.path.replace(":id", value));
    },
    [navigate]
  );

  useEffect(() => {
    mutateGetInvestmentsList();
  }, [mutateGetInvestmentsList]);
  return (
    <>
      {responseGetInvestmentsList.isPending && <>Загружаю...</>}

      {!responseGetInvestmentsList.isPending &&
        responseGetInvestmentsList.isFailed && (
          <Message variant="error">
            Не удалось загрузить список проектов
          </Message>
        )}

      {!responseGetInvestmentsList.isPending &&
        responseGetInvestmentsList.isSuccess && (
          <>
            {formattedData.length > 0 ? (
              <ProjectsTable
                list={formattedData}
                onClickItem={handleClickItem}
              />
            ) : (
              <Message variant="info">Проекты не найдены</Message>
            )}
          </>
        )}
    </>
  );
});

export default MonitoringInvestments;
