import { ReactElement, forwardRef, memo } from "react";
import useId from "../../hooks/use-id";

import { CheckboxProps } from "./checkbox.types";
import Message from "../message";
import "./checkbox.scss";

const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>(
    (
      { label, id, message, status, className, ...props },
      ref
    ): ReactElement | null => {
      const checkboxId = useId("checkbox", id);

      return (
        <div className={`checkbox${className ? ` ${className}` : ""}`}>
          <div className={`checkbox__body${status ? ` -${status}` : ""}`}>
            <input
              {...props}
              ref={ref}
              type="checkbox"
              id={checkboxId}
              className="checkbox__input"
            />
            <label htmlFor={checkboxId} className="checkbox__icon">
              <svg
                width="11"
                height="9"
                viewBox="0 0 11 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="checkbox__iconcheck"
              >
                <path d="M5.24219 7.78125C4.8125 8.21094 4.14844 8.21094 3.71875 7.78125L1.21875 5.28125C0.789062 4.85156 0.789062 4.1875 1.21875 3.75781C1.64844 3.32812 2.3125 3.32812 2.74219 3.75781L4.5 5.47656L8.71875 1.25781C9.14844 0.828125 9.8125 0.828125 10.2422 1.25781C10.6719 1.6875 10.6719 2.35156 10.2422 2.78125L5.24219 7.78125Z" />
              </svg>
            </label>

            {label && (
              <label htmlFor={checkboxId} className="checkbox__label">
                {label}
              </label>
            )}
          </div>

          {message && (
            <Message
              variant={status ? status : "info"}
              className="checkbox__message"
            >
              {message}
            </Message>
          )}
        </div>
      );
    }
  )
);

export default Checkbox;
