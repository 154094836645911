import { FC, memo } from "react";

import "./project-card-other.scss";

import ProjectsList from "../../../ui/projects-list";

interface ProjectCardOtherProps {}

const ProjectCardOther: FC<ProjectCardOtherProps> = memo(() => {
  return (
    <section className="project-card-other wrap">
      <h2 className="project-card-other__title">Другие проекты</h2>
      <ProjectsList limit={4} />
    </section>
  );
});

export default ProjectCardOther;
