import { FC, memo, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "../../../routing";

import Button from "../../../ui/button";

import "./payment-wallet.scss";

interface PaymentWalletProps {
  value: number;
  currency: string;
  onAddBalances?: () => void;
}

const PaymentWallet: FC<PaymentWalletProps> = memo(
  ({ value, currency, onAddBalances }) => {
    const navigate = useNavigate();

    const handleClickSupport = useCallback(() => {
      navigate(routes.feedback.path);
    }, [navigate]);

    const handleAddBalances = useCallback(() => {
      if (onAddBalances) {
        onAddBalances();
      }
    }, [onAddBalances]);

    return (
      <div className="payment-wallet">
        <h2 className="payment-wallet__title">Кошелек</h2>
        <div className="payment-wallet__balance">
          <b>Баланс</b>
          <span>
            {value} {currency}
          </span>
        </div>
        <Button
          type="button"
          className="-purpule -size-s"
          onClick={handleAddBalances}
        >
          Пополнить баланс
        </Button>
        <button
          className="payment-wallet__support"
          onClick={handleClickSupport}
        >
          Написать в поддержку
        </button>
      </div>
    );
  }
);

export default PaymentWallet;
