import { FC, memo, useCallback, useEffect } from "react";
import format from "date-fns/format";
import ruLocate from "date-fns/locale/ru";

import useMutation from "../../hooks/use-mutation";

import { getPaymentOperations } from "../../api";

import Message from "../../ui/message";
import CopyText from "../../ui/copy-text";

import "./payment-operations-list.scss";

const typeStatus = {
  income: "Зачислен",
  outcome: "Вывод",
  invest: "Инвестирование в проект",
};

type TypeStatusKeyType = keyof typeof typeStatus;

interface PaymentOperationsListProps {
  onRefresh?: () => void;
}

const PaymentOperationsList: FC<PaymentOperationsListProps> = memo(
  ({ onRefresh }) => {
    const [mutateGetPaymentOperations, responseGetPaymentOperations] =
      useMutation(getPaymentOperations);

    const handleRefresh = useCallback(() => {
      mutateGetPaymentOperations();

      if (onRefresh) {
        onRefresh();
      }
    }, [mutateGetPaymentOperations, onRefresh]);

    useEffect(() => {
      mutateGetPaymentOperations();
    }, [mutateGetPaymentOperations]);

    return (
      <div className="payment-operations">
        <div className="payment-operations__top">
          <h2 className="payment-operations__title">История операций</h2>
          <button
            type="button"
            onClick={handleRefresh}
            className="payment-operations__buttonrefresh"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.75 0.40625L13.875 3C12.4688 1.3125 10.3438 0.25 8 0.25C7.96875 0.25 7.96875 0.25 7.96875 0.25C4.1875 0.25 1.03125 2.96875 0.375 6.5625C0.375 6.59375 0.375 6.625 0.375 6.65625C0.375 6.84375 0.53125 7 0.75 7H2.28125C2.4375 7 2.59375 6.90625 2.625 6.71875C3.21875 4.3125 5.375 2.53125 7.96875 2.53125C9.875 2.53125 11.5625 3.46875 12.5312 4.9375L9.375 4.78125H9.34375C9.15625 4.78125 8.96875 4.96875 8.96875 5.15625V6.65625C8.96875 6.84375 9.15625 7.03125 9.34375 7.03125H15.625C15.8125 7.03125 16 6.84375 16 6.65625V0.375C16 0.1875 15.8125 0 15.625 0H14.125C13.9375 0.03125 13.75 0.1875 13.75 0.375C13.75 0.40625 13.75 0.40625 13.75 0.40625ZM7.96875 13.5C6.0625 13.5 4.40625 12.5625 3.40625 11.0938L6.59375 11.25H6.625C6.8125 11.25 7 11.0625 7 10.875V9.375C7 9.1875 6.8125 9 6.625 9H0.375C0.15625 9 0 9.1875 0 9.375V15.625C0 15.8438 0.15625 16 0.375 16H1.84375C2.03125 16 2.21875 15.8438 2.21875 15.625L2.09375 13.0312C3.5 14.7188 5.625 15.7812 7.96875 15.7812V15.75C11.75 15.75 14.9062 13.0625 15.5938 9.46875C15.5938 9.4375 15.5938 9.40625 15.5938 9.375C15.5938 9.1875 15.4375 9 15.2188 9H13.6875C13.5 9.03125 13.375 9.125 13.3125 9.3125C12.75 11.7188 10.5625 13.5 7.96875 13.5Z"
                fill="#5E07DD"
              />
            </svg>
            Обновить данные
          </button>
        </div>

        {responseGetPaymentOperations.isPending && <>Загружаю...</>}
        {!responseGetPaymentOperations.isPending &&
          responseGetPaymentOperations.isFailed && (
            <Message variant="error">Не удалось загрузить данные</Message>
          )}

        {!responseGetPaymentOperations.isPending &&
          responseGetPaymentOperations.isSuccess &&
          responseGetPaymentOperations.data && (
            <>
              {responseGetPaymentOperations.data.response.length > 0 ? (
                <>
                  <div className="payment-operations-list -header">
                    <div>Дата</div>
                    <div>Сумма</div>
                    <div>Адрес</div>
                    <div>TXid</div>
                  </div>
                  {responseGetPaymentOperations.data.response.map(
                    (item: any) => (
                      <div
                        key={item.id}
                        className="payment-operations-list -items"
                      >
                        <div className="payment-operations-list__item">
                          <div className="payment-operations-list__title">
                            Дата:
                          </div>
                          <div className="payment-operations-list__value">
                            {format(
                              new Date(item.created_at),
                              "dd.MM.yyyy HH:mm",
                              {
                                locale: ruLocate,
                              }
                            )}
                          </div>
                        </div>
                        <div className="payment-operations-list__item">
                          <div className="payment-operations-list__title">
                            Сумма:
                          </div>
                          <div className="payment-operations-list__value">
                            {item.amount.value} {item.amount.currency}
                            <br />
                            <div className="payment-operations-list__type">
                              {typeStatus[item.type as TypeStatusKeyType]}
                            </div>
                          </div>
                        </div>
                        <div className="payment-operations-list__item">
                          <div className="payment-operations-list__title">
                            Адрес:
                          </div>
                          <div className="payment-operations-list__value">
                            <div className="payment-operations-list__nowrap">
                              {item.address}
                            </div>
                          </div>
                        </div>
                        <div className="payment-operations-list__item">
                          <div className="payment-operations-list__title">
                            TXid:
                          </div>
                          <div className="payment-operations-list__value -copy">
                            <div className="payment-operations-list__nowrap">
                              {item.hash}
                            </div>
                            <CopyText message={item.hash} />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <Message variant="info">
                  Здесь будут отображаться ваши последние транзакции.
                </Message>
              )}
            </>
          )}
      </div>
    );
  }
);

export default PaymentOperationsList;
