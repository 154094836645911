import { FC, memo, useState, useCallback } from "react";

import PaymentAddBalances from "../payment/payment-add-balances";
import WalletInfo from "./wallet-info";

interface WalletProps {}

const Wallet: FC<WalletProps> = memo(() => {
  const [variant, setVariant] = useState<"start" | "balances">("start");

  const handleAddBalances = useCallback(() => {
    setVariant("balances");
  }, []);

  const handleСancelAddBalances = useCallback(() => {
    setVariant("start");
  }, []);

  return (
    <main className="wrap main wallet">
      <div className="wallet__body">
        {variant === "start" && (
          <WalletInfo onAddBalances={handleAddBalances} />
        )}

        {variant === "balances" && (
          <PaymentAddBalances onСancel={handleСancelAddBalances} />
        )}
      </div>
    </main>
  );
});

export default Wallet;
