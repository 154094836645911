import { useCallback, useState, useRef, useEffect } from 'react';

const useMutation = <T, U extends any[]>(operation: (...args: U) => Promise<{status: number; response: T}>) => {
  const [data, setData] = useState<{status: number; response: T}>();
  const [error, setError] = useState<Error>();
  const [isPending, setIspending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const mounted = useRef<boolean>(true);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  const mutate = useCallback(
    (...args: U) => {
      setIspending(true);
      operation(...args)
        .then((res) => {
          if (mounted.current) {
            console.log('sucess, set data')
            setError(undefined);

            console.log(res);

            if (res.status < 400) {
              setIsSuccess(true);
              setIsFailed(false);
            } else {
              setIsSuccess(false);
              setIsFailed(true);
            }

            setData(res);
                  
          }
        })
        .catch((err) => { 
          if (mounted.current) {
            console.log('error, set error');
            setError(err);
            setIsFailed(true);
          }          
        })
        .finally(() => {
          if (mounted.current) {
            console.log('finally');
            setIspending(false);
          }  
         
        });
    },
    [operation],
  );

  const _reset = useCallback(() => {
    setData(undefined);
    setError(undefined);
    setIspending(false);
    setIsFailed(false);
    setIsSuccess(false);
  }, []);

  return [mutate, { data, isPending, isSuccess, isFailed, error, _reset }] as const;
};

export default useMutation;