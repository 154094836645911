import { FC, memo, useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import useMutation from "../../../hooks/use-mutation";
import { editProfileData } from "../../../api";

import { emailRegex } from "../../../helpers";

import Button from "../../../ui/button";
import TextInput from "../../../ui/text-input";
import Checkbox from "../../../ui/checkbox";
import Message from "../../../ui/message";
import CopyText from "../../../ui/copy-text";

interface ProfileFormValuesProps {
  email: string;
  email_subscribe: boolean;
}

interface ProfileFormDataProps {
  email: string;
  email_subscribe: boolean;
  public_address?: string;
}

interface ProfileFormProps {
  dataForm: ProfileFormDataProps;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Введите ваш e-mail")
    .matches(emailRegex, "Укажите корректный e-mail"),
});

export const ProfileForm: FC<ProfileFormProps> = memo(({ dataForm }) => {
  const [mutateEditProfileData, responseEditProfileData] =
    useMutation(editProfileData);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ProfileFormValuesProps>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: dataForm.email,
      email_subscribe: dataForm.email_subscribe,
    },
  });

  const onSubmit = useCallback(
    (value: ProfileFormValuesProps) => {
      mutateEditProfileData(value);
    },
    [mutateEditProfileData]
  );

  return (
    <form className="profile__form" onSubmit={handleSubmit(onSubmit)}>
      {!responseEditProfileData.isPending &&
        responseEditProfileData.isFailed && (
          <Message variant="error" className="profile__message">
            Не удалось изменить данные
          </Message>
        )}

      {!responseEditProfileData.isPending &&
        responseEditProfileData.isSuccess && (
          <Message variant="success" className="profile__message">
            Данный успешно сохранены
          </Message>
        )}

      <div className="profile__rows">
        <div className="profile__fiedname">Кошелек</div>
        <div className="profile__field">
          {dataForm.public_address && (
            <div className="profile__address">
              <div className="profile__fiedtext">{dataForm.public_address}</div>
              <CopyText message={dataForm.public_address} />
            </div>
          )}
        </div>
        <div className="profile__fiedname">Почта</div>
        <div className="profile__field">
          <TextInput
            {...register("email")}
            status={errors.email ? "error" : undefined}
            message={errors.email?.message ? errors.email?.message : ""}
          />
        </div>
      </div>
      <div className="profile__checkbox">
        <Checkbox
          label="Получать рассылку на почту"
          {...register("email_subscribe")}
        />
      </div>
      <div className="profile__button">
        <Button
          type="submit"
          className="-purpule -size-m"
          disabled={!isValid || responseEditProfileData.isPending}
        >
          Сохранить
        </Button>
      </div>
    </form>
  );
});
