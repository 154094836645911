import { FC, memo, MouseEvent, useCallback } from "react";

import "./projects-table.scss";
interface ProjectsTableItemProps {
  id: string;
  imageSrc: string;
  name: string;
  price: string;
  status: string;
  profitabilityPercent: string;
  cost: string;
  earning: string;
  amount: string;
}

interface ProjectsTableProps {
  list: Array<ProjectsTableItemProps>;
  onClickItem?: (value: string) => void;
}

const ProjectsTable: FC<ProjectsTableProps> = memo(({ list, onClickItem }) => {
  const handleClickItem = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const value = event.currentTarget.dataset.id;

      if (value && onClickItem) {
        onClickItem(value);
      }
    },
    [onClickItem]
  );

  if (list.length === 0) {
    return null;
  }

  return (
    <>
      <div className="projects-table">
        <div className="projects-table__item -header">Проект</div>
        <div className="projects-table__item -header">Стоимость</div>
        <div className="projects-table__item -header">Доход</div>
        <div className="projects-table__item -header">Инвестиции</div>
        <div className="projects-table__item -header">Статус</div>
        <div className="projects-table__item -header">Прогноз</div>
      </div>
      {list.map((item) => (
        <div
          className={`projects-table -items${
            onClickItem ? " -bg-with-hover" : " -bg"
          }`}
          key={item.id}
          data-id={item.id}
          onClick={handleClickItem}
        >
          <div className="projects-table__item">
            <div className="projects-table__descr">
              <div className="projects-table__img">
                <img src={item.imageSrc} alt={item.name} />
              </div>
              <div className="projects-table__info">
                <div className="projects-table__name projects-table-text-overflow">
                  {item.name}
                </div>
                <div className="projects-table__price">{item.price}</div>
              </div>
            </div>
          </div>

          <div className="projects-table__item -cost">
            <div className="projects-table-text-overflow">{item.cost}</div>
          </div>
          <div className="projects-table__item -cost">
            <div className="projects-table-text-overflow">
              {item.earning ? item.earning : "-"}
            </div>
          </div>
          <div className="projects-table__item -cost">
            <div className="projects-table-text-overflow">
              {item.amount ? item.amount : "-"}
            </div>
          </div>
          <div className="projects-table__item">
            <div className="projects-table-text-overflow">{item.status}</div>
          </div>
          <div className="projects-table__item">
            <div className="projects-table__percent">
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.796875 6.29688C1.03125 6.53125 1.38281 6.50781 1.61719 6.29688L4.3125 3.46094V10.1875C4.3125 10.5156 4.54688 10.75 4.875 10.75H5.625C5.92969 10.75 6.1875 10.5156 6.1875 10.1875V3.46094L8.85938 6.27344C9.09375 6.50781 9.44531 6.50781 9.67969 6.29688L10.1953 5.78125C10.4062 5.54688 10.4062 5.19531 10.1953 4.98438L5.625 0.414062C5.41406 0.203125 5.0625 0.203125 4.85156 0.414062L0.28125 4.98438C0.0703125 5.19531 0.0703125 5.54688 0.28125 5.78125L0.796875 6.29688Z"
                  fill="#5E07DD"
                />
              </svg>
              {item.profitabilityPercent}%
            </div>
          </div>
        </div>
      ))}
    </>
  );
});

export default ProjectsTable;
