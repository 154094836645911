import { FC, memo } from "react";

import "./about.scss";

import ImgAbout1 from "./about1.svg";
import ImgAbout2 from "./about2.svg";

interface AboutProps {}

const About: FC<AboutProps> = memo(() => {
  return (
    <section className="about wrap">
      <div className="about__item -invert">
        <div className="about__img">
          <img src={ImgAbout1} alt="" className="about__picture -img1" />
        </div>
        <div className="about__text">
          Инвестиции в&nbsp;недвижимость всегда оставались{" "}
          <b>самыми надежными и&nbsp;привлекательными</b>. При этом порог входа
          оставался всегда высоким за&nbsp;счет высокой итоговой стоимости
          объекта.
          <br />
          <br />
          Цены на&nbsp;недвижимость не&nbsp;подвержены сильной волатильности
          и&nbsp;всегда сохраняют восходящую тенденцию.
        </div>
      </div>
      <div className="about__item">
        <div className="about__img">
          <img src={ImgAbout2} alt="" className="about__picture -img2" />
        </div>
        <div className="about__text">
          Теперь инвестировать в&nbsp;недвижимость стало не&nbsp;только удобнее,
          но&nbsp;и&nbsp;безопастнее за&nbsp;счет современных технологий.
          <br />
          <br />
          Наш фонд позволяет инвестировать как малым так и&nbsp;крупным
          инвесторам, предлагая разные варианты инвестирования{" "}
          <b>от&nbsp;1&nbsp;м&sup2;</b>&nbsp;до&nbsp;
          <b>полного выкупа объекта</b>.
        </div>
      </div>
    </section>
  );
});

export default About;
