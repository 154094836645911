export const routes = {
  root: {
    path: "/",
  },
  exit: {
    path: "/",
  },
  projects: {
    path: "/projects",
  },
  projectView: {
    path: "/projects/view/:id",
  },
  monitoring: {
    path: "/monitoring",
  },
  wallet: {
    path: "/wallet",
  },
  profile: {
    path: "/profile",
  },
  feedback: {
    path: "/feedback",
  },
  payment: {
    path: "/projects/payment/:id",
  },
};