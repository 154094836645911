import { FC, memo, ChangeEvent, useCallback, useMemo, useEffect } from "react";

import useMutation from "../../../hooks/use-mutation";

import { numberRegex } from "../../../helpers";

import { postInvestments } from "../../../api";

import Button from "../../../ui/button";
import TextInput from "../../../ui/text-input";
import Notice from "../../../ui/notice";
import ActionPlusMinus from "../../../ui/action-plus-minus";

import "./payment-calc.scss";

interface PaymentCalcBalancesProps {
  value: number;
  currency: string;
}

interface PaymentCalcProps {
  projectId: number;
  price: number;
  currency: string;
  balances: PaymentCalcBalancesProps;
  squareValue: number;
  maxSquare?: number;
  onChangeSquareValue: (value: number) => void;
  onSuccess?: () => void;
  onAddBalances?: () => void;
}

const PaymentCalc: FC<PaymentCalcProps> = memo(
  ({
    projectId,
    maxSquare,
    squareValue,
    price,
    currency,
    balances,
    onChangeSquareValue,
    onSuccess,
    onAddBalances,
  }) => {
    const [mutatePostInvestments, responsePostInvestments] =
      useMutation(postInvestments);

    const costValue = useMemo(() => {
      return squareValue * price;
    }, [squareValue, price]);

    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        let currentValue = event.target.value;

        if (currentValue.match(numberRegex)) {
          currentValue = currentValue.replace(numberRegex, "");
        }

        if (!maxSquare || Number(currentValue) <= maxSquare) {
          onChangeSquareValue(Number(currentValue));
        }
      },
      [maxSquare, onChangeSquareValue]
    );

    const handleChangeSquareValue = useCallback(
      (value: number) => {
        onChangeSquareValue(value);
      },
      [onChangeSquareValue]
    );

    const handleClickInvest = useCallback(() => {
      mutatePostInvestments({
        project_id: projectId,
        amount: { value: costValue, currency: currency },
      });
    }, [mutatePostInvestments, projectId, currency, costValue]);

    const handleAddBalances = useCallback(() => {
      if (onAddBalances) {
        onAddBalances();
      }
    }, [onAddBalances]);

    useEffect(() => {
      if (
        !responsePostInvestments.isPending &&
        responsePostInvestments.isSuccess
      ) {
        //TODO: возможно не нужно обнулять
        responsePostInvestments._reset();
        if (onSuccess) {
          onSuccess();
        }
      }
    }, [responsePostInvestments, onSuccess]);

    return (
      <div className="payment-calc">
        <h2 className="payment-calc__title">Сумма инвестирования</h2>
        <div className="payment-calc__rows">
          <div className="payment-calc__label">
            <div className="payment-calc__square">
              Площадь
              <ActionPlusMinus
                value={squareValue}
                maxValue={maxSquare}
                onChangeValue={handleChangeSquareValue}
              />
            </div>
          </div>
          <div className="payment-calc__value -square">
            <TextInput
              name="square"
              onChange={handleOnChange}
              value={`${squareValue}`}
              className="payment-calc__fieldsquare"
            />
            {maxSquare && <span>кв. м. из {maxSquare} кв. м.</span>}
          </div>
          <div className="payment-calc__label">Стоимость</div>
          <div className="payment-calc__value">
            {costValue && (
              <>
                {costValue} {currency}
              </>
            )}
          </div>
        </div>

        {costValue && costValue > balances.value ? (
          <>
            <Notice>
              На вашем кошельке не достаточно средств, пополните баланс.
            </Notice>
            <Button
              type="button"
              className="-purpule -size-m"
              onClick={handleAddBalances}
            >
              Пополнить баланс
            </Button>
          </>
        ) : (
          <Button
            type="button"
            className="-purpule -size-m"
            onClick={handleClickInvest}
            disabled={squareValue <= 0}
          >
            Инвестировать с баланса
          </Button>
        )}
      </div>
    );
  }
);

export default PaymentCalc;
