import { FC, memo, useEffect } from "react";

import useMutation from "../../../../hooks/use-mutation";
import { getIncomesInfo } from "../../../../api";

import Button from "../../../../ui/button";
import Message from "../../../../ui/message";
import CopyText from "../../../../ui/copy-text";

import Timer from "../../../../ui/timer";

interface PaymentAddBalancesInfoProps {
  sum?: number;
  currency?: string;
  onNext: () => void;
  onСancel: () => void;
}

const PaymentAddBalancesInfo: FC<PaymentAddBalancesInfoProps> = memo(
  ({ sum, currency, onСancel, onNext }) => {
    const [mutateGetIncomesInfo, responseGetIncomesInfo] =
      useMutation(getIncomesInfo);

    useEffect(() => {
      mutateGetIncomesInfo();
    }, [mutateGetIncomesInfo]);

    return (
      <>
        {responseGetIncomesInfo.isPending && <>Загружаю...</>}

        {!responseGetIncomesInfo.isPending &&
          responseGetIncomesInfo.isFailed && (
            <Message variant="error">Не удалось загрузить данные</Message>
          )}

        {!responseGetIncomesInfo.isPending &&
          responseGetIncomesInfo.isSuccess &&
          responseGetIncomesInfo.data && (
            <>
              <p className="payment-balances__text">
                Переведите USDT на указанный ниже кошелек, внимательно
                проверяйте все данные.
              </p>

              <div className="payment-balances__info">
                <div className="payment-balances__infolabel">Валюта:</div>
                <div>{responseGetIncomesInfo.data.response.currency}</div>
                <div className="payment-balances__infolabel">Сеть:</div>
                <div>{responseGetIncomesInfo.data.response.network}</div>
                <div className="payment-balances__infolabel">Сумма:</div>
                <div>
                  {sum && currency ? (
                    <div className="payment-balances__fieldcopy">
                      {sum} {currency}
                      <CopyText message={`${sum}`} />
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
                <div className="payment-balances__infolabel">Адрес:</div>
                <div>
                  {responseGetIncomesInfo.data.response.address && (
                    <div className="payment-balances__fieldcopy">
                      <div className="payment-balances__infoaddress">
                        {responseGetIncomesInfo.data.response.address}
                      </div>
                      <CopyText
                        message={responseGetIncomesInfo.data.response.address}
                      />
                    </div>
                  )}
                </div>
              </div>

              <ul className="payment-balances__note">
                <li className="-highlighted">
                  Внимание, может взиматься комиссия за использование сети со
                  стороны Blockchain.
                </li>
                <li>
                  Отправляйте на этот адрес только USDT. Отправка любой другой
                  монеты или токена на этот адрес может привести к потере
                  средств.
                </li>
                <li>
                  Убедитесь, что сеть верна:{" "}
                  {responseGetIncomesInfo.data.response.network}.
                </li>
              </ul>

              <Timer
                expiryTime={300} //5 min
                remainsTime={30}
                notifyMessage="Если вы совершили оплату в течение 1 часа после того как время закончилось, она все-равно будет зачислена на баланс."
              />

              <div className="payment-balances__buttons">
                <Button
                  type="button"
                  className="-purpule -size-m"
                  onClick={onNext}
                >
                  Далее
                </Button>
                <Button
                  type="button"
                  className="-transparent -size-m"
                  onClick={onСancel}
                >
                  Отмена
                </Button>
              </div>
            </>
          )}
      </>
    );
  }
);

export default PaymentAddBalancesInfo;
