import { ReactElement, forwardRef, memo } from "react";

import { TextareaInputProps } from "./textarea-input.types";
import Message from "../message";
import "./textarea-input.scss";

const TextareaInput = memo(
  forwardRef<HTMLTextAreaElement, TextareaInputProps>(
    (props, ref): ReactElement | null => {
      const { label, message, status, className, ...otherProps } = props;

      return (
        <div className="textarea-input">
          {label && <>{label}</>}
          <textarea
            {...otherProps}
            ref={ref}
            rows={1}
            className={`textarea-input__field${status ? ` -${status}` : ""}${
              className ? ` ${className}` : ""
            }`}
          ></textarea>

          {message && (
            <Message
              variant={status ? status : "info"}
              className="textarea-input__message"
            >
              {message}
            </Message>
          )}
        </div>
      );
    }
  )
);

export default TextareaInput;
