import { FC, memo, useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import useMutation from "../../../../hooks/use-mutation";
import { addSubscribe } from "../../../../api";

import { emailRegex } from "../../../../helpers";

import "./form-subscribe.scss";

import Button from "../../../../ui/button";
import NotificationSubscribe from "../notification-subscribe";

interface FormSubscribeProps {}

interface FormSubscribeValuesProps {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Введите ваш e-mail")
    .matches(emailRegex, "Укажите корректный e-mail"),
});

const FormSubscribe: FC<FormSubscribeProps> = memo(() => {
  const [mutateAddSubscribe, responseAddSubscribe] = useMutation(addSubscribe);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormSubscribeValuesProps>({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = useCallback(
    (value: FormSubscribeValuesProps) => {
      mutateAddSubscribe(value.email);
    },
    [mutateAddSubscribe]
  );

  return (
    <>
      <div className="form-subscribe">
        <div className="form-subscribe__text">
          Подпишись на&nbsp;нашу рассылку, чтобы оставаться в&nbsp;курсе событий
          и&nbsp;не&nbsp;пропускать важные анонсы.
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-subscribe__content">
            <div className="form-subscribe__fieldbox">
              <input
                type="text"
                placeholder="Введите ваш e-mail"
                className="form-subscribe__field"
                {...register("email")}
              />
              {errors.email?.message && (
                <div className="form-subscribe__error">
                  {errors.email.message}
                </div>
              )}
            </div>

            <Button
              type="submit"
              className="-purpule -size-l"
              disabled={responseAddSubscribe.isPending}
            >
              Подписаться
            </Button>
          </div>
        </form>
      </div>

      {!responseAddSubscribe.isPending && responseAddSubscribe.isSuccess && (
        <NotificationSubscribe status="success" />
      )}

      {!responseAddSubscribe.isPending && responseAddSubscribe.isFailed && (
        <NotificationSubscribe status="failed" />
      )}
    </>
  );
});

export default FormSubscribe;
