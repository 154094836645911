import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/email/subscribe?email=DATA
 *
 */

 export async function addSubscribe(data: string) {
   const result = await fetchFromApi({url: `/email/subscribe?email=${data}`, method: 'POST', isPublic: true});
   
  return result;
}