import { FC, memo, useCallback } from "react";

import "./start.scss";
import BnbChain from "./bnb-chain.svg";

import videoMp4 from "./background.mp4";
import videoWebm from "./background.webm";

import Button from "../../../ui/button";

interface StartProps {
  gotoScreen?: (name: string) => void;
}

const Start: FC<StartProps> = memo(({ gotoScreen }) => {
  const handleClickButtonProject = useCallback(() => {
    if (gotoScreen) {
      gotoScreen("projects");
    }
  }, [gotoScreen]);

  return (
    <section className="start">
      <video muted autoPlay loop className="start__video">
        <source src={videoWebm} type="video/webm" />
        <source src={videoMp4} type="video/mp4" />
      </video>
      <div className="start__body wrap">
        <div className="start__content">
          <h1 className="start__title">
            Инвестиции в&nbsp;недвижимость через Blockchain
          </h1>
          <p className="start__text">
            Любой желающий имеет возможность инвестировать в&nbsp;небольшие доли
            выбранного объекта используя для этого технологии Blockchain.
          </p>
          <Button className="-blue -size-l" onClick={handleClickButtonProject}>
            Выбрать проект
          </Button>
        </div>
        <div className="start__extra">
          <div className="start__extratext">
            Будущее инвестиций
            <br />
            в&nbsp;недвижимость
            <br />
            уже здесь.
          </div>
          <div className="start__technology">
            <span>На основе</span>
            <div className="start__technologyicon">
              <img src={BnbChain} alt="BNB Chain" />
            </div>
            <span>BNB Chain</span>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Start;
