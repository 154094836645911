import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/investments
 *
 */

 interface PostInvestmentsAmountData {
  value: number;
  currency: string;
}

 export interface PostInvestmentsData {
  project_id: number;
  amount: PostInvestmentsAmountData;
}

 export async function postInvestments(data: PostInvestmentsData) {
  const result = await fetchFromApi({url: `/investments`, method: 'POST', data: data});
   
  return result;
}