import { FC, memo, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import useMutation from "../../hooks/use-mutation";
import { getProfileData, sendSupportMessage } from "../../api";

import Button from "../../ui/button";
import TextareaInput from "../../ui/textarea-input";
import Message from "../../ui/message";
import Headline from "../../ui/headline";
import Notice from "../../ui/notice";

import "./feedback.scss";

interface FeedbackProps {}

interface FormValuesProps {
  text: string;
}

const validationSchema = Yup.object().shape({
  text: Yup.string().required("Напишите ваш вопрос"),
});

const Feedback: FC<FeedbackProps> = memo(() => {
  const [mutateGetProfileData, responseGetProfileData] =
    useMutation(getProfileData);

  const [mutateSendSupportMessage, responseSendSupportMessage] =
    useMutation(sendSupportMessage);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<FormValuesProps>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text: "",
    },
  });

  const onSubmit = useCallback(
    (value: FormValuesProps) => {
      mutateSendSupportMessage(value);
    },
    [mutateSendSupportMessage]
  );

  useEffect(() => {
    mutateGetProfileData();
  }, [mutateGetProfileData]);
  return (
    <>
      <main className="wrap main feedback">
        <Headline>Обратная связь</Headline>
        <div className="feedback__body">
          {responseGetProfileData.isPending && <>Загружаю...</>}

          {!responseSendSupportMessage.isPending &&
            responseSendSupportMessage.isFailed && (
              <Message variant="error" className="feedback__message">
                Не удалось отправить сообщение
              </Message>
            )}

          {!responseSendSupportMessage.isPending &&
            responseSendSupportMessage.isSuccess && (
              <Message variant="success" className="feedback__message">
                Ваше сообщение успешно отправлено
              </Message>
            )}

          {!responseGetProfileData.isPending &&
            responseGetProfileData.isSuccess &&
            responseGetProfileData.data &&
            !responseGetProfileData.data.response.email && (
              <Notice>
                Заполните ваш e-mail адрес в разделе Настройки. Перейти в{" "}
                <Link to="/profile">
                  <b>Профиль →</b>
                </Link>
              </Notice>
            )}

          <form className="feedback__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="feedback__field">
              <TextareaInput
                placeholder="Напишите ваш вопрос, ответ придет на почту указанную в вашем профиле."
                {...register("text")}
                status={errors.text ? "error" : undefined}
                message={
                  errors.text?.message ? errors.text?.message : undefined
                }
              />
            </div>
            <div className="feedback__button">
              <Button
                type="submit"
                className="-purpule -size-m"
                disabled={
                  (!responseGetProfileData.isPending &&
                    responseGetProfileData.isSuccess &&
                    responseGetProfileData.data &&
                    !responseGetProfileData.data.response.email) ||
                  !isValid ||
                  responseSendSupportMessage.isPending
                }
              >
                Отправить сообщение
              </Button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
});

export default Feedback;
