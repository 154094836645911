import { FC, memo } from "react";

import Button from "../../../../ui/button";

interface PaymentAddBalancesFinishProps {
  onBack: () => void;
}

const PaymentAddBalancesFinish: FC<PaymentAddBalancesFinishProps> = memo(
  ({ onBack }) => {
    return (
      <>
        <p className="payment-balances__text">
          Cпасибо за вашу оплату, как деньги поступят вы увидети их в истории
          операций и на балансе кошелька.
        </p>

        <div className="payment-balances__buttons">
          <Button type="button" className="-purpule -size-m" onClick={onBack}>
            Вернуться на страницу оплаты
          </Button>
        </div>
      </>
    );
  }
);

export default PaymentAddBalancesFinish;
