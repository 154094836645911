import { FC, memo } from "react";

import FooterLinks from "../footer-links";

import "./footer.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = memo(() => {
  return (
    <footer className="footer">
      <div className="wrap footer__body">
        <div className="footer__info">
          <svg
            className="footer__logo"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0V24H7.6V7.81482H24V0H0Z" fill="#D9D9D9" />
            <rect x="12" y="12" width="12" height="12" fill="#D9D9D9" />
          </svg>
          <div className="footer__support">
            Служба поддержки:{" "}
            <a href="mailto:support@cryptometers.io">support@cryptometers.io</a>
          </div>
        </div>
        <FooterLinks isForAdmin />
      </div>
    </footer>
  );
});

export default Footer;
