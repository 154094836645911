import { FC, memo, useCallback } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./project-card-media.scss";

interface ProjectCardMediaItemProps {
  type: string;
  url: string;
}

interface ProjectCardMediaProps {
  list: Array<ProjectCardMediaItemProps>;
}

const ProjectCardMedia: FC<ProjectCardMediaProps> = memo(({ list }) => {
  const handleOnSlideChange = useCallback(() => {
    const videos = document.querySelectorAll("video");

    videos.forEach(function (video) {
      video.pause();
      video.currentTime = 0;
    });
  }, []);

  if (list.length === 0) {
    return null;
  }

  return (
    <section className="project-card-media">
      <div className="project-card-media__box">
        <Swiper
          modules={[Navigation, Pagination]}
          onSlideChange={handleOnSlideChange}
          navigation={true}
          pagination={true}
        >
          {list.map((item: any) => (
            <SwiperSlide key={item.url}>
              <div className="project-card-media__item">
                {item.type === "image" && <img src={item.url} alt="" />}
                {item.type === "video" && (
                  <video controls poster={item.image_preview}>
                    <source
                      src={item.url}
                      type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                    />
                    Тег video не поддерживается вашим браузером.
                    <a href="video/duel.mp4">Скачайте видео</a>.
                  </video>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
});

export default ProjectCardMedia;
