import { FC, memo } from "react";

import "./header-main.scss";

import HeaderNavigate from "../../../ui/header-navigate";
import LogoMain from "../../../ui/logo-main";

interface HeaderMainProps {
  gotoScreen?: (name: string) => void;
}

const menuItems = [
  { target: "about", value: "О фонде" },
  { target: "how-works", value: "Как работает" },
  { target: "benefit", value: "Преимущества" },
  { target: "projects", value: "Проекты" },
  { target: "footer", value: "Контакты" },
];

const HeaderMain: FC<HeaderMainProps> = memo(({ gotoScreen }) => {
  return (
    <header className="header-main">
      <div className="header-main__body wrap">
        <LogoMain className="header-main__logo" />

        <HeaderNavigate
          customClass="header-main__nav"
          menuList={{
            baseClass: "header-main",
            handleClicItem: gotoScreen,
            items: menuItems,
          }}
        ></HeaderNavigate>
      </div>
    </header>
  );
});

export default HeaderMain;
