import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/investments/summary
 *
 */


 export async function getInvestmentsSummary() {
  const result = await fetchFromApi({url: `/investments/summary`, method: 'GET'});
   
  return result;
}