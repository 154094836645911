import { FC, memo, MouseEvent, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import format from "date-fns/format";
import ruLocate from "date-fns/locale/ru";

import { routes } from "../../routing";
import useMutation from "../../hooks/use-mutation";
import { getProjectsList } from "../../api";

import Message from "../../ui/message";

import "./projects-list.scss";

interface ProjectsListProps {
  status?: string;
  limit?: number;
  favorite?: boolean;
}

const ProjectsList: FC<ProjectsListProps> = memo(
  ({ status = "", limit, favorite = false }) => {
    const navigate = useNavigate();

    const [mutateGetProjectsList, responseGetProjectsList] =
      useMutation(getProjectsList);

    const handleClickItem = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        const value = event.currentTarget.dataset.id;

        if (value) {
          navigate(routes.projectView.path.replace(":id", value));
        }
      },
      [navigate]
    );

    useEffect(() => {
      const params: ProjectsListProps = {};

      if (status) {
        params.status = status;
      }

      if (limit) {
        params.limit = limit;
      }

      if (favorite) {
        params.favorite = favorite;
      }

      mutateGetProjectsList(params);
    }, [mutateGetProjectsList, status, limit, favorite]);

    return (
      <>
        {responseGetProjectsList.isPending && <>Загружаю...</>}

        {!responseGetProjectsList.isPending &&
          responseGetProjectsList.isFailed && (
            <Message variant="error">
              Не удалось загрузить список проектов
            </Message>
          )}

        {!responseGetProjectsList.isPending &&
          responseGetProjectsList.isSuccess &&
          responseGetProjectsList.data && (
            <>
              {responseGetProjectsList.data.response.length > 0 ? (
                <div className="projects-list">
                  {responseGetProjectsList.data?.response.map((item: any) => (
                    <div
                      className="projects-list__item"
                      key={item.id}
                      data-id={item.id}
                      onClick={handleClickItem}
                    >
                      <img
                        src={item.image_profile}
                        alt={item.name}
                        className="projects-list__img"
                      />
                      <div className="projects-list__title">{item.name}</div>
                      <div className="projects-list__info">
                        <div>
                          Цена за м²
                          <div className="projects-list__value -pice">
                            {item.price.value} {item.price.currency}
                          </div>
                        </div>
                        <div>
                          Завершается
                          <br />
                          <div className="projects-list__value">
                            {item.building_end_at &&
                              format(
                                new Date(item.building_end_at),
                                "dd.MM.yyyy",
                                { locale: ruLocate }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Message variant="info">Проекты не найдены</Message>
              )}
            </>
          )}
      </>
    );
  }
);

export default ProjectsList;
