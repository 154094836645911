import { FC, memo } from "react";

import "./how-works.scss";

interface HowWorksProps {}

const HowWorks: FC<HowWorksProps> = memo(() => {
  return (
    <section className="how-works wrap">
      <h2 className="how-works__title">Как работает фонд</h2>
      <div className="how-works__list">
        <div className="how-works__item">
          <h3 className="how-works__itemtitle">
            Отбираем проекты для инвестирования
          </h3>
          <p>
            Выбираем <b>высокодоходные</b> объекты, учитывая юридические
            и&nbsp;финансовые требования.
          </p>
        </div>
        <div className="how-works__item">
          <h3 className="how-works__itemtitle">Полностью выкупаем</h3>
          <p>
            До&nbsp;этапа строительства, когда стоимость за&nbsp;квадратный метр{" "}
            <b>минимальная</b>.
          </p>
        </div>
        <div className="how-works__item">
          <h3 className="how-works__itemtitle">
            Реализуем объект по&nbsp;рыночной цене
          </h3>
          <p>
            После завершения строительства объекта с&nbsp;доходностью{" "}
            <b>от&nbsp;50% до&nbsp;200%</b>.
          </p>
        </div>
      </div>
      <div className="how-works__info">
        <h3 className="how-works__infotitle">
          Нет возможности купить кваритру целиком?
        </h3>
        <p className="how-works__text">
          Теперь вы&nbsp;можете преобрести <b>часть в&nbsp;объекте</b> стоимость
          которого начинается <b>от&nbsp;1&nbsp;м&sup2;</b>.
        </p>
      </div>
      <div className="how-works__info">
        <h3 className="how-works__infotitle">
          Самостоятельно реализуем объект
        </h3>
        <p className="how-works__text">
          Это <b>сокращает ваши затраты</b> на сделки по недвижимости и
          обеспечивает <b>более высокую доходность</b>.
        </p>
      </div>
    </section>
  );
});

export default HowWorks;
