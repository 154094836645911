import { FC, memo, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMutation from "../../../hooks/use-mutation";
import { addFavourite, deleteFavourite } from "../../../api";

import Headline from "../../../ui/headline";

import { routes } from "../../../routing";

import "./project-card-header.scss";

interface ProjectCardHeaderProps {
  title: string;
  id: number;
  favourite?: boolean;
}

const ProjectCardHeader: FC<ProjectCardHeaderProps> = memo(
  ({ title, id, favourite = false }) => {
    const navigate = useNavigate();
    const [isFavorites, setIsFavorites] = useState(favourite);

    const [mutateAddFavourite, responseAddFavourite] =
      useMutation(addFavourite);

    const [mutateDeleteFavourite, responseDeleteFavourite] =
      useMutation(deleteFavourite);

    const handleClickFavourites = useCallback(() => {
      if (isFavorites) {
        mutateDeleteFavourite(id);
      } else {
        mutateAddFavourite(id);
      }
    }, [isFavorites, mutateAddFavourite, mutateDeleteFavourite, id]);

    const handleClickBack = useCallback(() => {
      navigate(routes.projects.path);
    }, [navigate]);

    useEffect(() => {
      if (!responseAddFavourite.isPending && responseAddFavourite.isSuccess) {
        responseAddFavourite._reset();
        setIsFavorites(true);
      }
    }, [responseAddFavourite]);

    useEffect(() => {
      if (
        !responseDeleteFavourite.isPending &&
        responseDeleteFavourite.isSuccess
      ) {
        responseDeleteFavourite._reset();
        setIsFavorites(false);
      }
    }, [responseDeleteFavourite]);

    return (
      <section className="project-card-header wrap">
        <button
          type="button"
          title="Назад"
          onClick={handleClickBack}
          className="project-card-header__back"
        >
          <svg
            width="36"
            height="24"
            viewBox="0 0 36 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36 14.376H9.312L15.36 20.424L12.336 23.496L0.72 11.88L12.336 0.263998L15.36 3.336L9.36 9.336H36V14.376Z"
              fill="#0C0D2B"
            />
          </svg>
        </button>
        <div className="project-card-header__middle">
          <Headline>{title}</Headline>
          <button
            type="button"
            title={
              isFavorites ? "Удалить из избранного" : "Добавить в избранное"
            }
            className="project-card-header__favourites"
            onClick={handleClickFavourites}
            disabled={
              responseAddFavourite.isPending ||
              responseDeleteFavourite.isPending
            }
          >
            <svg
              width="34"
              height="33"
              viewBox="0 0 34 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isFavorites ? (
                <path
                  d="M22.8125 9.4375L31.75 10.75C32.5 10.875 33.125 11.375 33.375 12.125C33.625 12.8125 33.4375 13.625 32.875 14.125L26.375 20.5625L27.9375 29.6875C28.0625 30.4375 27.75 31.1875 27.125 31.625C26.5 32.125 25.6875 32.125 25 31.8125L17 27.5L8.9375 31.8125C8.3125 32.125 7.4375 32.125 6.875 31.625C6.25 31.1875 5.9375 30.4375 6.0625 29.6875L7.5625 20.5625L1.0625 14.125C0.5 13.625 0.3125 12.8125 0.5625 12.125C0.8125 11.375 1.4375 10.875 2.1875 10.75L11.1875 9.4375L15.1875 1.125C15.5 0.4375 16.1875 0 17 0C17.75 0 18.4375 0.4375 18.75 1.125L22.8125 9.4375Z"
                  fill="#5E07DD"
                />
              ) : (
                <path
                  d="M16.9375 0C17.5625 0 18.0625 0.375 18.3125 0.875L22.625 9.6875L32.1875 11.125C32.75 11.1875 33.1875 11.625 33.375 12.125C33.5625 12.6875 33.4375 13.25 33 13.6875L26.0625 20.5625L27.6875 30.25C27.8125 30.8125 27.5625 31.4375 27.125 31.75C26.625 32.0625 26 32.125 25.5 31.875L16.9375 27.25L8.375 31.875C7.875 32.125 7.3125 32.0625 6.8125 31.75C6.375 31.4375 6.125 30.8125 6.1875 30.25L7.875 20.5625L0.9375 13.6875C0.5 13.25 0.375 12.6875 0.5625 12.125C0.75 11.625 1.1875 11.1875 1.75 11.125L11.3125 9.6875L15.625 0.875C15.875 0.375 16.375 0 16.9375 0ZM16.9375 4.9375L13.6875 11.75C13.4375 12.1875 13.0625 12.5 12.5625 12.5625L5.125 13.625L10.5 18.9375C10.875 19.3125 11 19.8125 10.9375 20.3125L9.6875 27.75L16.25 24.25C16.6875 24 17.25 24 17.6875 24.25L24.25 27.75L23 20.3125C22.875 19.8125 23.0625 19.3125 23.4375 18.9375L28.75 13.625L21.375 12.5625C20.875 12.5 20.4375 12.1875 20.25 11.75L16.9375 4.9375Z"
                  fill="#5E07DD"
                />
              )}
            </svg>
          </button>
        </div>
      </section>
    );
  }
);

export default ProjectCardHeader;
