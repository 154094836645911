export const projectsStatus = [
  { value: "start", text: "Старт" },
  { value: "funded", text: "Финансируется" },
  { value: "completed", text: "Сбор средств завершен" },
  { value: "paid", text: "Выплачено инвесторам" },
];

export const projectStatusName = (type?: string) => {
  if (type) {
    const curStatus = projectsStatus.find((item) => {
      return item.value === type;
    });

    return curStatus ? curStatus.text : undefined;
  }

  return undefined;
};
