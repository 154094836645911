import { FetcherOptions,  FetcherResponse } from './api-fetcher.types';

async function fetchFromApi(props: FetcherOptions):Promise<FetcherResponse> {
  const token = localStorage.getItem("access_token");

  let headers: {'Content-Type': string; 'Authorization'?: string} = {
    'Content-Type': 'application/json'
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch("https://cryptometers.io/api" + props.url, {
    method: props.method,
    headers: headers,
    body: JSON.stringify(props.data)
  });

  const text = await response.text();
  const result = text.length ? JSON.parse(text) : {}; 


  return {
    status: response.status,
    response: result
  };
}

export default fetchFromApi;
