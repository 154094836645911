import { FC, memo, useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useMutation from "../../hooks/use-mutation";
import { getProjectById } from "../../api";
import { routes } from "../../routing";

import Message from "../../ui/message";
import ProjectCardMedia from "./project-card-media";
import ProjectCardHeader from "./project-card-header";
import ProjectCardInfo from "./project-card-info";
import ProjectCardDocumentation from "./project-card-documentation";
//import ProjectCardChart from "./project-card-chart";
//import ProjectCardСalculator from "./project-card-calculator";
import ProjectCardTeam from "./project-card-team";
import ProjectCardOther from "./project-card-other";
import ProjectCardCover from "./project-card-cover";
import ProjectCardGallery from "./project-card-gallery";
import ProjectCardButtonInvest from "./project-card-button-invest";

import "./project-card.scss";

interface ProjectCardProps {}

const ProjectCard: FC<ProjectCardProps> = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [squareValue, setSquareValue] = useState(1);

  const [mutateGetProjectById, responseGetProjectById] =
    useMutation(getProjectById);

  const galleryList = useMemo(() => {
    if (
      !responseGetProjectById.isPending &&
      responseGetProjectById.isSuccess &&
      responseGetProjectById.data &&
      responseGetProjectById.data.response.gallery_files
    ) {
      return responseGetProjectById.data.response.gallery_files.map(
        (item: any) => {
          return item.url;
        }
      );
    }

    return undefined;
  }, [responseGetProjectById]);

  const handleClickInvest = useCallback(
    (projectId: string) => {
      navigate({
        pathname: routes.payment.path.replace(":id", projectId),
        search: `?square=${squareValue}`,
      });
    },
    [navigate, squareValue]
  );

  const handleChangeSquareValue = useCallback(
    (value: number) => {
      setSquareValue(value);
    },
    [setSquareValue]
  );

  useEffect(() => {
    if (id) {
      mutateGetProjectById({ id: id });
    }
  }, [mutateGetProjectById, id]);

  return (
    <main className="main project-card">
      {responseGetProjectById.isPending && <>Загружаю...</>}
      {!responseGetProjectById.isPending && responseGetProjectById.isFailed && (
        <Message variant="error">Не удалось загрузить проект</Message>
      )}
      {!responseGetProjectById.isPending &&
        responseGetProjectById.isSuccess &&
        responseGetProjectById.data && (
          <>
            <ProjectCardMedia
              list={responseGetProjectById.data.response.slider_files}
            />
            <ProjectCardHeader
              title={responseGetProjectById.data.response.title}
              favourite={responseGetProjectById.data.response.favourite}
              id={responseGetProjectById.data.response.id}
            />
            <ProjectCardInfo
              squareValue={squareValue}
              projectId={responseGetProjectById.data.response.id}
              imageLogo={responseGetProjectById.data.response.image_logo}
              category={responseGetProjectById.data.response.category}
              location={responseGetProjectById.data.response.location}
              description={responseGetProjectById.data.response.description}
              square={responseGetProjectById.data.response.square}
              square_area={responseGetProjectById.data.response.square_area}
              collected_square={
                responseGetProjectById.data.response.collected_square
              }
              cost={responseGetProjectById.data.response.cost}
              collected_amount={
                responseGetProjectById.data.response.collected_amount
              }
              profitabilityPercent={
                responseGetProjectById.data.response.profitability_percent
              }
              price={responseGetProjectById.data.response.price}
              buildingStart={
                responseGetProjectById.data.response.building_start_at
              }
              buildingEnd={responseGetProjectById.data.response.building_end_at}
              investStart={responseGetProjectById.data.response.invest_start_at}
              investEnd={responseGetProjectById.data.response.invest_end_at}
              status={responseGetProjectById.data.response.status}
              onClickInvest={handleClickInvest}
              onChangeSquareValue={handleChangeSquareValue}
            />

            {responseGetProjectById.data.response.image_project_page && (
              <ProjectCardCover
                src={responseGetProjectById.data.response.image_project_page}
              />
            )}

            {responseGetProjectById.data.response.documents && (
              <ProjectCardDocumentation
                data={responseGetProjectById.data.response.documents}
              />
            )}

            {/**  <ProjectCardChart /> <ProjectCardСalculator /> */}

            {responseGetProjectById.data.response.team_peoples && (
              <ProjectCardTeam
                data={responseGetProjectById.data.response.team_peoples}
              />
            )}

            {galleryList && galleryList.length && (
              <ProjectCardGallery list={galleryList} />
            )}

            <ProjectCardButtonInvest
              projectId={responseGetProjectById.data.response.id}
              onClickInvest={handleClickInvest}
            />
          </>
        )}

      <ProjectCardOther />
    </main>
  );
});

export default ProjectCard;
