import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/incomes/info
 *
 */


 export async function getIncomesInfo() {
  const result = await fetchFromApi({url: `/incomes/info`, method: 'GET'});
   
  return result;
}