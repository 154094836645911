import { FC, memo, useCallback } from "react";

import Button from "../../../ui/button";

import "./scheme-work.scss";

import ImgSchemeWorkBg from "./scheme-work-bg.svg";

interface SchemeWorkProps {
  gotoScreen?: (name: string) => void;
}

const SchemeWork: FC<SchemeWorkProps> = memo(({ gotoScreen }) => {
  const handleClickButtonProject = useCallback(() => {
    if (gotoScreen) {
      gotoScreen("projects");
    }
  }, [gotoScreen]);

  return (
    <section className="scheme-work wrap">
      <h2 className="scheme-work__title">Общая схема работы фонда</h2>
      <div className="scheme-work__body">
        <img src={ImgSchemeWorkBg} alt="" className="scheme-work__img" />
        <ul className="scheme-work__list">
          <li className="scheme-work__listitem -step1">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Оценка <br />
            недвижмости
          </li>
          <li className="scheme-work__listitem -step2">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Презентация <br />
            объекта
          </li>
          <li className="scheme-work__listitem -step3">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Реализация <br />
            объекта
          </li>
          <li className="scheme-work__listitem -step4">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Сбор <br />
            инвестиций
          </li>
          <li className="scheme-work__listitem -step5">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Расчет с инвесторами <br />
            через личный кабинет <br />
            на сайте
          </li>
          <li className="scheme-work__listitem -step6">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="12.5" strokeWidth="5" />
            </svg>
            Сделки <br />
            осуществляются <br />
            через смарт-контракт
          </li>
        </ul>
        <ul className="scheme-work__note">
          <li className="scheme-work__noteitem">
            Договора заключаются электронно.
          </li>
          <li className="scheme-work__noteitem">Отображаются в самой сети.</li>
          <li className="scheme-work__noteitem">Считаются неизменными.</li>
        </ul>
        <Button className="-blue -size-l" onClick={handleClickButtonProject}>
          Выбрать проект
        </Button>
      </div>
    </section>
  );
});

export default SchemeWork;
