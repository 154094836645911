import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/investments?order_by=id&order_flow=asc&page=1&offset=0&limit=10&status=
 *
 */

 export interface GetInvestmentsListData {
  order_by?: string;
  order_flow?: string;
  page?: number;
  offset?: number;
  limit?: number;
  status?:string;
  favorite?: boolean;
}

 export async function getInvestmentsList(data?: GetInvestmentsListData) {
  const result = await fetchFromApi({url: `/investments`, method: 'GET', data: data});
   
  return result;
}