import { FC, memo, useCallback, useState } from "react";

import Tabs from "../../ui/tabs";
import ProjectsList from "../../ui/projects-list";
import Headline from "../../ui/headline";

import "./projects.scss";
import { projectsStatus } from "../../helpers";

interface ProjectsProps {}

const Projects: FC<ProjectsProps> = memo(() => {
  const [status, setStatus] = useState<string>("start");

  const handleClickTabItem = useCallback((value: string) => {
    setStatus(value);
  }, []);

  return (
    <>
      <main className="wrap main projects">
        <Headline>Проекты</Headline>

        <Tabs
          list={projectsStatus}
          current="start"
          onClickItem={handleClickTabItem}
        />

        <ProjectsList status={status} />
      </main>
    </>
  );
});

export default Projects;
