import { FC, memo } from "react";

import "./project-card-gallery.scss";

interface ProjectCardGalleryProps {
  list: Array<string>;
}

const ProjectCardGallery: FC<ProjectCardGalleryProps> = memo(({ list }) => {
  return (
    <section className="project-card-gallery wrap">
      <h2 className="project-card-gallery__title">Галерея</h2>

      <div className="project-card-gallery__list">
        {list.map((item: string) => (
          <div key={item}>
            <img src={item} alt="" />
          </div>
        ))}
      </div>
    </section>
  );
});

export default ProjectCardGallery;
