import { FC, memo } from "react";

import "./project-card-team.scss";

interface ProjectCardTeamItemProps {
  description: string;
  image: string;
  job: string;
  name: string;
}

interface ProjectCardTeamProps {
  data: Array<ProjectCardTeamItemProps>;
}

const ProjectCardTeam: FC<ProjectCardTeamProps> = memo(({ data }) => {
  return (
    <section className="project-card-team wrap">
      <h2 className="project-card-team__title">Команда проекта</h2>
      <div className="project-card-team__list">
        {data.map((item: any) => (
          <div className="project-card-team__item" key={item.name}>
            <img
              src={item.image}
              alt={item.name}
              className="project-card-team__img"
            />
            <div className="project-card-team__itemname">
              <b>{item.name}</b>
              <br />
              {item.job}
            </div>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
});

export default ProjectCardTeam;
