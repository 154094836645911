import { FC, MouseEvent, memo, useState, useCallback } from "react";

import "./tabs.scss";

interface TabsItemProps {
  value: string;
  text: string;
}

interface TabsProps {
  list: Array<TabsItemProps>;
  current: string;
  onClickItem?: (value: string) => void;
}

const Tabs: FC<TabsProps> = memo(({ list, current, onClickItem }) => {
  const [tabValue, setTabValue] = useState<string>(current);

  const handleClickTabItem = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setTabValue(event.currentTarget.value);

      if (onClickItem) {
        onClickItem(event.currentTarget.value);
      }
    },
    [setTabValue, onClickItem]
  );

  if (list.length === 0) {
    return null;
  }

  return (
    <ul className="tabs">
      {list.map((item: any) => (
        <li
          key={item.value}
          className={`tabs__item${tabValue === item.value ? " -active" : ""}`}
        >
          <button value={item.value} onClick={handleClickTabItem}>
            {item.text}
          </button>
        </li>
      ))}
    </ul>
  );
});

export default Tabs;
