import { FC, memo } from "react";

import "./footer-main.scss";

import LogoMain from "../../../ui/logo-main";
import FooterLinks from "../../../ui/footer-links";

import FormSubscribe from "./form-subscribe";

interface FooterMainProps {}

const FooterMain: FC<FooterMainProps> = memo(() => {
  return (
    <footer className="footer-main">
      <div className="wrap">
        <div className="footer-main__top">
          <div className="footer-main__info">
            <LogoMain className="footer-main__logo" />
            Для предложений по&nbsp;сотрудничеству
            <br />
            <a href="mailto:info@cryptometers.io">info@cryptometers.io</a>
          </div>

          <FormSubscribe />
        </div>
        <div className="footer-main__bottom">
          <div>
            Используя наш сайт, вы&nbsp;соглашаетесь с&nbsp;нашими
            <FooterLinks />
          </div>
          <div className="footer-main__copyright">
            &copy;&nbsp;2020-2022&nbsp;&mdash; все права защищены 丨
            cryptometers.io
          </div>
        </div>
      </div>
    </footer>
  );
});

export default FooterMain;
