import { ReactElement, forwardRef, memo } from "react";

import { TextInputProps } from "./text-input.types";
import Message from "../message";
import "./text-input.scss";

const TextInput = memo(
  forwardRef<HTMLInputElement, TextInputProps>(
    (
      { label, id, message, status, className, type = "text", ...props },
      ref
    ): ReactElement | null => {
      return (
        <div className={`text-input${className ? ` ${className}` : ""}`}>
          {label && <>{label}</>}
          <input
            {...props}
            type={type}
            ref={ref}
            className={`text-input__field${status ? ` -${status}` : ""}`}
          />

          {message && (
            <Message
              variant={status ? status : "info"}
              className="text-input__message"
            >
              {message}
            </Message>
          )}
        </div>
      );
    }
  )
);

export default TextInput;
