import { FC, memo, useCallback } from "react";

import "./copy-text.scss";

interface CopyTextProps {
  message: string;
}

const CopyText: FC<CopyTextProps> = memo(({ message }) => {
  const handlerClickCopyText = useCallback(() => {
    navigator.clipboard.writeText(message);
  }, [message]);

  return (
    <button type="button" className="copy-text" onClick={handlerClickCopyText}>
      <svg
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8398 2.55469L10.4453 1.16016C10.1992 0.914062 9.87109 0.75 9.51562 0.75H4.8125C4.07422 0.75 3.5 1.35156 3.5 2.0625V3.375H1.3125C0.574219 3.375 0 3.97656 0 4.6875V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H7.4375C8.14844 14.75 8.75 14.1758 8.75 13.4375V12.125H10.9375C11.6484 12.125 12.25 11.5508 12.25 10.8125V3.48438C12.25 3.12891 12.0859 2.80078 11.8398 2.55469ZM7.27344 13.4375H1.47656C1.36719 13.4375 1.3125 13.3828 1.3125 13.2734V4.85156C1.3125 4.76953 1.36719 4.6875 1.47656 4.6875H3.5V10.8125C3.5 11.5508 4.07422 12.125 4.8125 12.125H7.4375V13.2734C7.4375 13.3828 7.35547 13.4375 7.27344 13.4375ZM10.7734 10.8125H4.97656C4.86719 10.8125 4.8125 10.7578 4.8125 10.6484V2.22656C4.8125 2.14453 4.86719 2.0625 4.97656 2.0625H7.875V4.46875C7.875 4.85156 8.14844 5.125 8.53125 5.125H10.9375V10.6484C10.9375 10.7578 10.8555 10.8125 10.7734 10.8125ZM10.9375 3.8125H9.1875V2.0625H9.43359C9.48828 2.0625 9.51562 2.08984 9.54297 2.11719L10.8828 3.45703C10.9102 3.48438 10.9375 3.51172 10.9375 3.56641V3.8125Z"
          fill="#5E07DD"
        />
      </svg>
    </button>
  );
});

export default CopyText;
