import { FC, memo, useCallback } from "react";

import Button from "../../../ui/button";

import "./project-card-button-invest.scss";

interface ProjectCardButtonInvestProps {
  projectId: string;
  onClickInvest: (projectId: string) => void;
}

const ProjectCardButtonInvest: FC<ProjectCardButtonInvestProps> = memo(
  ({ projectId, onClickInvest }) => {
    const handleClickInvest = useCallback(() => {
      onClickInvest(projectId);
    }, [onClickInvest, projectId]);

    return (
      <section className="project-card-button-invest wrap">
        <Button className="-purpule -size-l" onClick={handleClickInvest}>
          Инвестировать
        </Button>
      </section>
    );
  }
);

export default ProjectCardButtonInvest;
