import { FC, memo, useEffect } from "react";

import format from "date-fns/format";
import ruLocate from "date-fns/locale/ru";

import useMutation from "../../../hooks/use-mutation";
import { getGuestProjectsList } from "../../../api";

import ConnectWallet from "./connect-wallet";
import Message from "../../../ui/message";

import "./projects-main.scss";

interface ProjectsMainProps {}

const ProjectsMain: FC<ProjectsMainProps> = memo(() => {
  const [mutateGetGuestProjectsList, responseGetGuestProjectsList] =
    useMutation(getGuestProjectsList);

  useEffect(() => {
    mutateGetGuestProjectsList({
      order_by: "id",
      order_flow: "asc",
      page: 1,
      offset: 0,
      limit: 4,
    });
  }, [mutateGetGuestProjectsList]);

  return (
    <section className="projects-main wrap">
      <h2 className="projects-main__title">Инвестиционные проекты</h2>
      {responseGetGuestProjectsList.isPending && <>Загружаю...</>}

      {!responseGetGuestProjectsList.isPending &&
        responseGetGuestProjectsList.isFailed && (
          <Message variant="error">
            Не удалось загрузить список проектов
          </Message>
        )}

      {!responseGetGuestProjectsList.isPending &&
        responseGetGuestProjectsList.isSuccess &&
        responseGetGuestProjectsList.data && (
          <>
            {responseGetGuestProjectsList.data.response.length > 0 ? (
              <div className="projects-main__list">
                {responseGetGuestProjectsList.data.response.map((item: any) => (
                  <div className="projects-main__item" key={item.id}>
                    <div className="projects-main__img">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="projects-main__subtitle">{item.name}</div>

                    {item.location && (
                      <div className="projects-main__info">
                        Расположение: {item.location}
                      </div>
                    )}

                    {(item.invest_end_at ||
                      (item.collected_amount && item.cost)) && (
                      <div className="projects-main__info">
                        {item.invest_end_at && (
                          <>
                            Сбор средств до:{" "}
                            {format(
                              new Date(item.invest_end_at),
                              "dd.MM.yyyy",
                              { locale: ruLocate }
                            )}
                            <br />
                          </>
                        )}
                        {item.collected_amount && item.cost && (
                          <>
                            Собрано:{" "}
                            <span>
                              {item.collected_amount.value} / {item.cost.value}{" "}
                              {item.cost.currency}
                            </span>
                          </>
                        )}
                      </div>
                    )}

                    <div className="projects-main__price">
                      1 м² /{" "}
                      <span>
                        {item.price.value}
                        {item.price.currency}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Message variant="info">Проекты не найдены</Message>
            )}
          </>
        )}

      <div className="projects-main__button">
        <ConnectWallet />
      </div>
    </section>
  );
});

export default ProjectsMain;
