import { FC, memo, useEffect } from "react";

import useMutation from "../../hooks/use-mutation";
import { getProfileData } from "../../api";

import Tabs from "../../ui/tabs";
import Message from "../../ui/message";
import Headline from "../../ui/headline";

import { ProfileForm } from "./profile-form";

import "./profile.scss";

interface ProfileProps {}

const tabItems = [{ value: "security", text: "Аккаунт и безопастность" }];

const Profile: FC<ProfileProps> = memo(() => {
  const [mutateGetProfileData, responseGetProfileData] =
    useMutation(getProfileData);

  useEffect(() => {
    mutateGetProfileData();
  }, [mutateGetProfileData]);

  return (
    <>
      <main className="wrap main profile">
        <Headline>Профиль</Headline>
        <Tabs list={tabItems} current="security" />
        <div className="profile__body">
          {responseGetProfileData.isPending && <>Загружаю...</>}

          {!responseGetProfileData.isPending &&
            (responseGetProfileData.isFailed ||
              (responseGetProfileData.isSuccess &&
                !responseGetProfileData.data)) && (
              <Message variant="error">
                Не удалось загрузить список данные
              </Message>
            )}

          {!responseGetProfileData.isPending &&
            responseGetProfileData.isSuccess &&
            responseGetProfileData.data && (
              <ProfileForm dataForm={responseGetProfileData.data.response} />
            )}
        </div>
      </main>
    </>
  );
});

export default Profile;
