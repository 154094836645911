import { FC, memo, ReactNode } from "react";

import Header from "../header";
import Footer from "../footer";

interface LayoutProps {
  children: ReactNode;
  variant?: "admin" | "index";
  hasHeader?: boolean;
  styleHeader?: "white" | "dark";
  hasFooter?: boolean;
}

const Layout: FC<LayoutProps> = memo(
  ({
    children,
    variant = "admin",
    hasHeader = true,
    styleHeader = "white",
    hasFooter = true,
  }) => {
    return (
      <div className={`page -${variant}`}>
        {hasHeader && <Header styleHeader={styleHeader} />}
        {children}
        {hasFooter && <Footer />}
      </div>
    );
  }
);

export default Layout;
