import { FC, memo, ReactNode } from "react";

import "./headline.scss";

interface HeadlineProps {
  children: ReactNode;
  className?: string;
}

const Headline: FC<HeadlineProps> = memo(({ children, className }) => {
  return (
    <h1 className={`headline${className ? " " + className : ""}`}>
      {children}
    </h1>
  );
});

export default Headline;
