import { FC, memo, useRef, useCallback } from "react";

import Start from "./start";
import About from "./about";
import HowWorks from "./how-works";
import SchemeWork from "./scheme-work";
import Info from "./info";
import Benefit from "./benefit";
import ProjectsMain from "./projects-main";

import HeaderMain from "./header-main";
import FooterMain from "./footer-main";

interface MainProps {}

const Main: FC<MainProps> = memo(() => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const howWorksRef = useRef<HTMLDivElement>(null);
  const benefitRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const scrollIntoScreen = useCallback((name: string) => {
    switch (name) {
      case "about":
        if (aboutRef.current) {
          aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "how-works":
        if (howWorksRef.current) {
          howWorksRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "benefit":
        if (benefitRef.current) {
          benefitRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "projects":
        if (projectsRef.current) {
          projectsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "footer":
        if (footerRef.current) {
          footerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
    }
  }, []);

  return (
    <>
      <HeaderMain gotoScreen={scrollIntoScreen} />

      <Start gotoScreen={scrollIntoScreen} />
      <div ref={aboutRef} className="scrollMarginTop">
        <About />
      </div>
      <div ref={howWorksRef} className="scrollMarginTop">
        <HowWorks />
      </div>
      <SchemeWork gotoScreen={scrollIntoScreen} />
      <Info />
      <div ref={benefitRef} className="scrollMarginTop">
        <Benefit />
      </div>
      <div ref={projectsRef} className="scrollMarginTop">
        <ProjectsMain />
      </div>
      <div ref={footerRef}>
        <FooterMain />
      </div>
    </>
  );
});

export default Main;
