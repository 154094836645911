import { FC, memo } from "react";

import "./project-card-documentation.scss";

interface ProjectCardDocumentationItemProps {
  extension: string;
  image: string;
  language: string;
  size_text: string;
  title: string;
  url: string;
}

interface ProjectCardDocumentationProps {
  data: Array<ProjectCardDocumentationItemProps>;
}

const ProjectCardDocumentation: FC<ProjectCardDocumentationProps> = memo(
  ({ data }) => {
    return (
      <section className="project-card-documentation wrap">
        <h2 className="project-card-documentation__title">
          Проектная документация
        </h2>
        <div className="project-card-documentation__list">
          {data.map((item: any) => (
            <a
              className="project-card-documentation__item"
              href={item.url}
              key={`${item.title}-${item.extension}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={item.image}
                alt={item.title}
                className="project-card-documentation__img"
              />
              <h3 className="project-card-documentation__itemtitle">
                {item.title}
              </h3>
              Язык: {item.language}, {item.extension}, {item.size_text}
            </a>
          ))}
        </div>
      </section>
    );
  }
);

export default ProjectCardDocumentation;
