import { ButtonHTMLAttributes, ReactElement, forwardRef, memo } from "react";

import "./button.scss";

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ children, className, ...props }, ref): ReactElement | null => {
      return (
        <button
          type="button"
          className={`button ${className}`}
          {...props}
          ref={ref}
        >
          {children}
        </button>
      );
    }
  )
);

export default Button;
