import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/me
 *
 */

 export interface SendSupportMessageData {
  text: string;
}

 export async function sendSupportMessage(data: SendSupportMessageData) {
  const result = await fetchFromApi({url: `/support`, method: 'POST', data: data});
   
  return result;
}