import { FC, memo, useState, useCallback } from "react";

import PaymentAddBalancesInfo from "./payment-add-balances-info";
import PaymentAddBalancesFinish from "./payment-add-balances-finish";

import "./payment-add-balances.scss";

interface PaymentAddBalancesProps {
  sum?: number;
  currency?: string;
  onСancel: () => void;
}

const PaymentAddBalances: FC<PaymentAddBalancesProps> = memo(
  ({ sum, currency, onСancel }) => {
    const [step, setStep] = useState<1 | 2>(1);

    const handleClickNext = useCallback(() => {
      setStep(2);
    }, []);

    return (
      <div className="payment-balances">
        <h2 className="payment-balances__title">Ввод средств</h2>
        <div
          className={`payment-balances__steps${step === 2 ? " -finish" : ""}`}
        >
          <span>Шаг {step} из 2</span>
        </div>
        {step === 1 && (
          <PaymentAddBalancesInfo
            sum={sum}
            currency={currency}
            onСancel={onСancel}
            onNext={handleClickNext}
          />
        )}

        {step === 2 && <PaymentAddBalancesFinish onBack={onСancel} />}
      </div>
    );
  }
);

export default PaymentAddBalances;
