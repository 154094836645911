import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/balances/summary
 *
 */


 export async function getBalancesSummary() {
  const result = await fetchFromApi({url: `/balances/summary`, method: 'GET'});
   
  return result;
}