import type { FC } from "react";
import { memo, useCallback } from "react";

import "./action-plus-minus.scss";

interface ActionPlusMinusProps {
  value: number;
  maxValue?: number;
  onChangeValue?: (value: number) => void;
}

const ActionPlusMinus: FC<ActionPlusMinusProps> = memo(
  ({ value, maxValue, onChangeValue }) => {
    const handleClickMinus = useCallback(() => {
      if (onChangeValue) {
        onChangeValue(value - 1);
      }
    }, [onChangeValue, value]);

    const handleClickPlus = useCallback(() => {
      if (onChangeValue) {
        onChangeValue(value + 1);
      }
    }, [onChangeValue, value]);

    return (
      <div className="action-plus-minus">
        <button
          type="button"
          title="Минус"
          onClick={handleClickMinus}
          className="action-plus-minus__button"
          disabled={value <= 1}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" />
            <path d="M11 18.7001V22.4801H29.06V18.7001H11Z" fill="white" />
          </svg>
        </button>
        <button
          type="button"
          title="Плюс"
          onClick={handleClickPlus}
          className="action-plus-minus__button"
          disabled={Boolean(maxValue && value >= maxValue)}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" />
            <path
              d="M18.5604 29.6401V22.4801H11.4004V18.7001H18.5604V11.5601H22.3404V18.7001H29.4604V22.4801H22.3404V29.6401H18.5604Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    );
  }
);

export default ActionPlusMinus;
