import { FC, memo, ReactNode, useEffect, useMemo, useState } from "react";

import { MetamaskContext } from "../../context/metamask-context";

interface ProviderMetamaskProps {
  children: ReactNode;
}

const ProviderMetamask: FC<ProviderMetamaskProps> = memo(({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const metamaskContextProviderValue = useMemo(
    () => ({
      isScriptMetamaskLoaded: isLoaded,
    }),
    [isLoaded]
  );

  useEffect(() => {
    let isCanceled = false;
    const script = document.createElement("script");

    const successLoadedScriptMetamask = () => {
      if (isCanceled) {
        return;
      }

      setIsLoaded(true);
    };

    script.src = `https://cdn.jsdelivr.net/npm/web3@latest/dist/web3.min.js`;
    script.type = "text/javascript";

    script.addEventListener("load", successLoadedScriptMetamask);

    document.body.appendChild(script);

    return () => {
      isCanceled = true;
      script.removeEventListener("load", successLoadedScriptMetamask);
    };
  }, []);

  return (
    <MetamaskContext.Provider value={metamaskContextProviderValue}>
      {children}
    </MetamaskContext.Provider>
  );
});

export default ProviderMetamask;
