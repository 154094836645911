import { FC, memo, useCallback, useState } from "react";

import Button from "../button";

import "./info-cookies.scss";

interface InfoCookiesProps {}

const InfoCookies: FC<InfoCookiesProps> = memo(() => {
  const [isAcceptCookies, setIsAcceptCookies] = useState(
    Boolean(localStorage.getItem("cookies") === "accept")
  );

  const handlerClickAccept = useCallback(() => {
    localStorage.setItem("cookies", "accept");
    setIsAcceptCookies(true);
  }, []);

  if (isAcceptCookies) {
    return null;
  }

  return (
    <div className="info-cookies">
      <div className="info-cookies__body wrap">
        <p>
          Наш сайт использует cookie. Продолжая им пользоваться, вы соглашаетесь
          на обработку персональных данных в соответствии с политикой
          конфиденциальности
        </p>
        <div className="info-cookies__button">
          <Button className="-purpule -size-s" onClick={handlerClickAccept}>
            Принимаю
          </Button>
        </div>
      </div>
    </div>
  );
});

export default InfoCookies;
