import { FC, memo, useEffect, useMemo, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import useMutation from "../../hooks/use-mutation";
import { getProjectInvestment, getBalancesSummary } from "../../api";

import Headline from "../../ui/headline";
import Message from "../../ui/message";
import ProjectsTable from "../../ui/projects-table";
import PaymentOperationsList from "../../ui/payment-operations-list";

import PaymentCalc from "./payment-calc";
import PaymentWallet from "./payment-wallet";
import PaymentFinish from "./payment-finish";
import PaymentAddBalances from "./payment-add-balances";

import { projectStatusName } from "../../helpers";

import "./payment.scss";

interface PaymentProps {}

const Payment: FC<PaymentProps> = memo(() => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const currentSquare = searchParams.get("square");
  const [variant, setVariant] = useState<"start" | "balances" | "finish">(
    "start"
  );
  const [squareValue, setSquareValue] = useState(
    currentSquare ? Number(currentSquare) : 1
  );

  const [mutateGetProjectInvestment, responseGetProjectInvestment] =
    useMutation(getProjectInvestment);

  const [mutateGetBalancesSummary, responseGetBalancesSummary] =
    useMutation(getBalancesSummary);

  const formattedProjectData = useMemo(() => {
    if (
      !responseGetProjectInvestment.isPending &&
      responseGetProjectInvestment.isSuccess &&
      responseGetProjectInvestment.data
    ) {
      const data = responseGetProjectInvestment.data.response;
      const statusProject = projectStatusName(data.project.status);

      return {
        id: data.project.id,
        imageSrc: data.project.image_profile,
        name: data.project.name,
        price: `${data.project.price.value} ${data.project.price.currency}`,
        status: statusProject ? statusProject : "-",
        profitabilityPercent: data.project.profitability_percent,
        cost: data.project.cost
          ? `${data.project.cost.value} ${data.project.cost.currency}`
          : "",
        earning: data.earning.value
          ? `${data.earning.value} ${data.earning.currency}`
          : "",
        amount: data.amount.value
          ? `${data.amount.value} ${data.amount.currency}`
          : "",
      };
    }

    return undefined;
  }, [responseGetProjectInvestment]);

  const paymentData = useMemo(() => {
    if (
      !responseGetProjectInvestment.isPending &&
      responseGetProjectInvestment.isSuccess &&
      responseGetProjectInvestment.data &&
      !responseGetBalancesSummary.isPending &&
      responseGetBalancesSummary.isSuccess &&
      responseGetBalancesSummary.data
    ) {
      const dataProject = responseGetProjectInvestment.data.response.project;
      const dataBalances = responseGetBalancesSummary.data.response;

      if (dataProject.price && dataBalances.available) {
        return {
          maxSquare:
            dataProject.square && dataProject.collected_square
              ? Number(dataProject.square) -
                Number(dataProject.collected_square)
              : undefined,
          price: Number(
            responseGetProjectInvestment.data.response.project.price.value
          ),
          currency:
            responseGetProjectInvestment.data.response.project.price.currency,
          balances: {
            value: Number(dataBalances.available.value),
            currency: dataBalances.available.currency,
          },
        };
      }
    }

    return undefined;
  }, [responseGetProjectInvestment, responseGetBalancesSummary]);

  const handleChangeSquareValue = useCallback(
    (value: number) => {
      setSquareValue(value);
    },
    [setSquareValue]
  );

  const handleSuccessInvest = useCallback(() => {
    setVariant("finish");
  }, []);

  const handleAddBalances = useCallback(() => {
    setVariant("balances");
  }, []);

  const handleСancelAddBalances = useCallback(() => {
    setVariant("start");
  }, []);

  const handleRefresh = useCallback(() => {
    mutateGetBalancesSummary();
  }, [mutateGetBalancesSummary]);

  useEffect(() => {
    if (id) {
      mutateGetProjectInvestment({ id: id });
      mutateGetBalancesSummary();
    }
  }, [mutateGetProjectInvestment, mutateGetBalancesSummary, id]);

  return (
    <main className="wrap main payment">
      <div className="payment__body">
        {(responseGetProjectInvestment.isPending ||
          responseGetBalancesSummary.isPending) && <>Загружаю...</>}

        {((!responseGetProjectInvestment.isPending &&
          responseGetProjectInvestment.isFailed) ||
          (!responseGetBalancesSummary.isPending &&
            responseGetBalancesSummary.isFailed)) && (
          <Message variant="error">Не удалось загрузить данные</Message>
        )}

        {variant === "start" && (
          <>
            <Headline>Оплата</Headline>
            <div className="payment__project">
              <ProjectsTable
                list={formattedProjectData ? [{ ...formattedProjectData }] : []}
              />
            </div>

            {paymentData && formattedProjectData && (
              <div className="payment__main">
                <PaymentCalc
                  projectId={formattedProjectData.id}
                  maxSquare={paymentData.maxSquare}
                  price={paymentData.price}
                  currency={paymentData.currency}
                  balances={paymentData.balances}
                  squareValue={squareValue}
                  onChangeSquareValue={handleChangeSquareValue}
                  onSuccess={handleSuccessInvest}
                  onAddBalances={handleAddBalances}
                />

                <PaymentWallet
                  value={paymentData.balances.value}
                  currency={paymentData.balances.currency}
                  onAddBalances={handleAddBalances}
                />
              </div>
            )}

            <div className="payment__history">
              <PaymentOperationsList onRefresh={handleRefresh} />
            </div>
          </>
        )}

        {variant === "balances" && paymentData && (
          <PaymentAddBalances
            sum={squareValue * Number(paymentData.price)}
            currency={paymentData.currency}
            onСancel={handleСancelAddBalances}
          />
        )}

        {variant === "finish" && (
          <PaymentFinish
            squareValue={squareValue}
            projectName={formattedProjectData?.name}
          />
        )}
      </div>
    </main>
  );
});

export default Payment;
