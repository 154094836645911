import { FC, memo, ReactNode } from "react";

import "./notice.scss";

interface NoticeProps {
  children: ReactNode;
}

const Notice: FC<NoticeProps> = memo(({ children }) => {
  return <div className="notice">{children}</div>;
});

export default Notice;
