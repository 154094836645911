import fetchFromApi from '../api-fetcher';

/**
 * @see https://cryptometers.io/api/projects_guest?order_by=id&order_flow=asc&page=1&offset=0&limit=10
 *
 */

 export interface GetGuestProjectsListData {
  order_by?: string;
  order_flow?: string;
  page?: number;
  offset?: number;
  limit?: number;
  status?:string;
}

 export async function getGuestProjectsList(data: GetGuestProjectsListData) {
  const params = Object.entries(data).map(([key, value]) => {
    return `${key}=${value}`;
  });

  const query = params.join('&');
   
  return await fetchFromApi({url: `/projects_guest?${query}`, method: 'GET', isPublic: true});
}